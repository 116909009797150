.forum-grid {
    display: grid;
    grid-template-columns: 20% auto;
    width: 95vw;
    height: 90vh;
    border: 1px solid black;
    border-radius: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;
}

.forum-sidebar {
    background-color: #02354e;
    border-radius: 10px 0px 0px 10px;
}

.forum-text-input-grid {
    display: grid;
    grid-template-rows: auto 15%;
}

.replying-text {
    color: #007FFF;
    font-weight: 550;
}

.forum-input {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 80px;
    resize: none;
    padding: 20px;
    padding-left: 20px;
    border: 2px solid gray;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
}


.forum-input-bottom {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    background-color: whitesmoke;
    padding: 10px;
    margin-top: -7px;
    border: 2px solid gray;
    border-top: none;
    border-radius: 0px 0px 5px 5px
}

.reply-input {
    border: 2px solid #007FFF;
}

.reply-input-bottom {
    border: 2px solid #007FFF;
    border-top: none;
}

.cancel-reply {
    margin-left: 10px;
}

.cancel-reply-icon {
    font-size: 20px;
    margin-top: 3px;
    font-weight: 300;
}

.cancel-flex {
    color: #FF033E;
    display: flex;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
}

.cancel-flex:hover {
    color: #d20334;
    display: flex;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
}

.paper-plane-icon {
    color: #02354e;
    padding-left: 10px;
    font-size: 27px;
    cursor: pointer;
    margin-top: 3px;
}



.sidebar-grid {
    display: grid;
    grid-template-rows: 15% auto auto;
    height: 100%;
    color: white;
    margin-left: 20%;
    flex-direction: column-reverse;
}

.active-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #03C03C;
    margin-top: 8px;
    margin-left: 10%;
}

.sidebar-text {
    font-size: 17px;
    font-weight: 250;
}

.forum-name-flex {
    display: grid;
    grid-template-columns: auto auto;
}

.sidebar-header {
    margin-left: 10%;
    margin-top: 5%;
    font-weight: 600;
    font-size: 17px;
}


.sidebar-grid-1 {
    border: 1px solid rgba(255, 255, 255, 0.332);
    border-bottom: none;
}

.sidebar-grid-2 {
    border: 1px solid rgba(255, 255, 255, 0.332);
    border-bottom: none;
}

.forum-second {
    position: relative;
    top: 20%;
    justify-content: left;
    column-gap: 20px;
    margin-left: 25px;
}

.forum-first {
    position: relative;
    top: 5%;
    justify-content: left;
    column-gap: 20px;
    margin-left: 25px;
}

.channels-header {
    font-size: 18px;
    font-weight: 600;
    margin-top: -2px;
}

.channel-name {
    font-weight: 300;
    font-size: 17px;
    cursor: pointer;
}

.channels-icon {
    font-size: 20px;
}

.sidebar-grid-3 {
    border: 1px solid rgba(255, 255, 255, 0.332);
}

.forum-text-area {
    display: grid;
    grid-template-columns: 100%;
    padding-left: 10%;
    max-height: 75vh;
    white-space: pre-wrap;
}

.close-replies {
    text-align: left;
    cursor: pointer;
    padding-left: 10%;
}

.forum-text-area-replies {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 10%;
    max-height: 75vh;
    white-space: pre-wrap;
}

.scroll-bottom {
    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;
    padding-top: 50px;
}

.replies-container {
    display: grid;
    grid-template-rows: 2% auto;
    overflow: scroll;
    border-left: 1px solid black;
    padding-top: 15px;
}

.forum-replies-text {
    margin-left: 10%;
    padding-top: 20px;
}

.text-area-container {
    background-color: rgb(250, 250, 250);
    border-radius: 0px 0px 10px 10px;
}

.forum-message-username {
    font-weight: 700;
    font-size: 18px;
}

.forum-message-username-reply {
    font-weight: 700;
    font-size: 18px;
    color: #007FFF;
}

.forum-date {
    margin-left: 10px;
    font-weight: 300;
    font-size: 16px;
    margin-top: -5px;
}

.blue {
    color: #007FFF;
    font-weight: 600;
    cursor: pointer;
}

.forum-date-reply {
    color: #007FFF;
    margin-left: 10px;
    font-weight: 300;
    font-size: 16px;
    margin-top: -5px;
}

.forum-date-reply-short {
    color: #007FFF;
    margin-left: 10px;
    font-weight: 300;
    font-size: 16px;
    margin-top: -5px;
    display: none;
}

.forum-date-short {
    display: none;
    margin-left: 10px;
    font-weight: 300;
    font-size: 16px;
    margin-top: -5px;
}

.forum-time {
    margin-left: 5px;
    font-weight: 300;
    font-size: 16px;
    margin-top: -5px;
}

.forum-time-reply {
    color: #007FFF;
    margin-left: 5px;
    font-weight: 300;
    font-size: 16px;
    margin-top: -5px;
}

.cannot-join-meeting-header-text-2 {
    text-align: center;
    padding: 20px 40px;
    font-size: 23px;
    color: #02354e;
    font-weight: 650;
}

.reply-button {
    margin-top: -10px;
    color: #007FFF;
    cursor: pointer;
    font-weight: 500;
}

.forum-text-flex {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}

.reply-flex {
    display: flex;
    gap: 20px
}

.text-picture {
    min-width: 55px;
    max-height: 55px;
    text-align: center;
    color: white;
    border-radius: 10px;
    font-weight: 700;
    margin-top: 2px;
}

.colors-0 {
    background-color: red
}

.colors-1 {
    background-color: red
}

.colors-2 {
    background-color: red
}

.colors-3 {
    background-color: purple
}

.colors-4 {
    background-color: green
}

.colors-5 {
    background-color: blue
}

.colors-6 {
    background-color: orange
}

.colors-7 {
    background-color: black
}

.colors-8 {
    background-color: brown
}

.colors-9 {
    background-color: navy
}

.forum-image {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
}

.book-meeting-link {
    text-decoration: none;
    margin-top: -10px;
    font-weight: 300;
    font-size: 14px;
}



.user-initials {
    margin-top: 10px;
    font-size: 22px;
    letter-spacing: -0.05ch;
    color: white
}

.forum-text {
    margin-top: -10px;
}

.text-tools-flex {
    display: flex;
    gap: 30px
}

.newline {
    font-weight: 300;
    font-size: 15px;
    margin-top: 2px;
}

.cannot-post-modal {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 550px;
    height: 250px;
    padding-bottom: 50px;
    background-color: white;
    z-index: 10;
    border-radius: 10px;
    border: 1px solid black;
    margin-top: -20%;
}

@media (max-width: 1800px) {
    .forum-text-input-grid {
        grid-template-rows: auto 17%;
    }
}

@media (max-width: 1500px) {
    .forum-text-input-grid {
        grid-template-rows: auto 23%;
    }

    .paper-plane-icon {
        font-size: 23px;
    }

    .forum-text-area {
        max-height: 69vh;
    }

    .forum-text-area-replies {
        max-height: 69vh;
    }


}

@media (max-width: 1200px) {
    .forum-sidebar {
        display: none;
    }

    .forum-grid {
        grid-template-columns: auto;
    }

    .forum-message-username {
        font-size: 15px;
    }

    .forum-message-username-reply {
        font-size: 15px;
    }

    .forum-date {
        display: none;
        font-size: 14px;
    }


    .forum-date-reply {
        display: none;
        font-size: 14px;
    }

    .forum-time {
        font-size: 14px;
    }

    .forum-time-reply {
        font-size: 14px;
    }

    .forum-text {
        font-size: 15px;
    }

    .forum-text-area-replies {
        padding-left: 5%;
    }

    .forum-date-reply-short {
        display: unset;
        font-size: 15px;
    }

    .forum-date-short {
        display: unset;
        font-size: 15px;
    }

    .forum-date-reply {
        display: none;
        font-size: 15px;
    }

    .forum-date {
        display: none;
    }

    .cannot-post-modal {
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 470px;
        height: 250px;
        padding-bottom: 50px;
        background-color: white;
        z-index: 10;
        border-radius: 10px;
        border: 1px solid black;
        margin-top: -20%;
    }

    .cannot-join-meeting-header-text-2 {
        text-align: center;
        padding: 20px 50px;
        font-size: 20px;
        color: #02354e;
        font-weight: 650;
    }

}

@media (max-width: 800px) {
    .replying-text {
        font-size: 14px;
        margin-top: 2px;
    }

    .cancel-reply {
        font-size: 14px;
        margin-top: 2px;
    }

    .replying-to-name {
        display: none;
    }

    .display-none {
        display: none;
    }

    .forum-text-area-replies {
        grid-template-columns: auto;
        padding-left: 0%;
    }

    .replies-container {
        padding-left: 0%;
    }

    .cannot-post-modal {
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 470px;
        height: 250px;
        padding-bottom: 50px;
        background-color: white;
        z-index: 10;
        border-radius: 10px;
        border: 1px solid black;
        margin-top: -40%;
    }
}

@media (max-width: 450px) {
    .newline {
        display: none;
    }

    .forum-text {
        padding-right: 25px;
    }

    .cancel-flex {
        gap: 5px;
    }

    .cancel-reply {
        margin-left: 0px;
    }

    .cannot-post-modal {
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 350px;
        height: 250px;
        padding-bottom: 50px;
        background-color: white;
        z-index: 10;
        border-radius: 10px;
        border: 1px solid black;
        margin-top: -40%;
    }

    .scroll-bottom {
        max-height: 68vh;
    }

    .forum-replies-text {
        max-height: 68vh;
    }

    .replies-container {
        max-height: 68vh;
    }

    .replies-container {
        border-left: none;
    }

    .paper-plane-icon {
        color: #02354e;
        padding-left: 10px;
        font-size: 20px;
        cursor: pointer;
        margin-top: 3px;
    }
}

.forum-login-text {
    color: #007FFF;
    font-weight: 600;
}