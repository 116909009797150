.signup-modal {
    height: 740px;
    width: 600px;
    border: 1px solid rgba(0, 0, 0, 0.544);
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    margin-top: 35px;
    border-radius: 15px;
    top: 0;
    bottom: 20px;
}

.signup-header-text {
    font-weight: 550;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}

.name-container {
    width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.569);
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
}

.active-border {
    border: 2px solid black;
    border-radius: 10px;
    border: block;
}

.active-border-red {
    border: 2px solid rgb(217, 57, 0);
    border-radius: 10px;
    border: block;
}


.first {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 40px;
}

.first-no-bottom {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 40px;
    border-bottom: none;
}

.last {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
}

.last-with-border {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.name-header {
    font-size: 12px;
    font-weight: 500;
    margin-left: 15px;
    padding-top: 10px;
    color: rgb(89, 87, 87);
}

.name-input {
    margin-left: 12px;
    position: relative;
    top: -10px;
    font-size: 18px;
    color: rgb(37, 35, 35);
    width: 95%;
    border: none;
}

.names-container {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.569);
    height: 140px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    width: 90%;
    margin-top: 25px;
    border-radius: 10px;
}

.name-message {
    margin-top: 10px;
    margin-left: 32px;
    font-size: 13px;
    color: rgb(89, 87, 87);
    font-weight: 500px;
}

.email-container {
    width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.569);
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    border-radius: 10px;
    background-color: #e0e0e0;
    margin-top: 20px;
}

.gray-1 {
    background-color: #e0e0e020;
}

.mentor-information {
    margin-left: 30px;
    font-size: 15px;
    font-weight: 450;
    margin-top: 20px;
}

.select-container {
    display: flex;
    justify-content: space-around;
    padding-left: 5px;
}

.select-mentor-info {
    border: 1px solid black;
    width: 150px;
    border-radius: 10px;
    font-weight: 400;
    color: rgb(37, 35, 35);
}

.select-mentor-info:focus {
    border: 2px solid #004CC4;
}


.policy-agreement {
    margin-left: 30px;
    font-size: 12px;
    font-weight: 600;
    color: rgb(76, 78, 78);
}

.agree-and-continue {
    font-weight: 800;
}

.link {
    color: #004CC4;
    text-decoration: underline;
    cursor: pointer;
}

.agree-and-continue-button {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: absolute;
    width: 90%;
    background-color: #E61E4D;
    border: none;
    height: 60px;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.agree-and-continue-button-clicked {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: absolute;
    width: 90%;
    background-color: green;
    border: none;
    height: 60px;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.agree-and-continue-button:active {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    background-color: #eb466d;
}

.red {
    color: rgb(217, 57, 0)
}

.error-text {
    color: rgb(217, 57, 0);
    font-size: 12px;
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.select-error {
    color: rgb(217, 57, 0);
    border: 1px solid rgb(217, 57, 0);
}

@media (max-width: 430px) {
    .signup-modal {
        width: 350px;
    }

    .select-mentor-info {
        width: 100px;
    }

    .select-container {
        justify-content: center;
        gap: 5px
    }

    .agree-and-continue-button {
        height: 50px;
        margin-top: 0px;
    }

    .agree-and-continue-button-clicked {
        height: 50px;
        margin-top: 0px;
    }

    .name-input {
        height: 30px;
        font-size: 14px;
    }
}