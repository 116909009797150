.info-meeting-container {
    display: grid;
    justify-content: center;
    margin-top: 200px;
    grid-template-columns: auto;
    margin-bottom: 400px;
}

.meetings-header {
    font-size: 40px;
    font-weight: 700;
    padding: 0px 20px;
}

.meetings-question {
    font-size: 20px;
    font-weight: 600;
    margin-top: 50px;
    padding: 0px 20px;
}

.meetings-answer {
    max-width: 800px;
    font-size: 18px;
    line-height: 3ch;
    padding: 0px 20px;
}

@media (max-width: 900px) {
    .meetings-answer {
        max-width: 600px;
        font-size: 16px;
        line-height: 3ch;
    }
}

@media (max-width: 430px) {
    .info-meeting-container {
        margin-top: 150px;
        margin-bottom: 200px;
    }
}