.mentor-grid-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-top: 10px;
    border: 3px solid white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.mentor-div {
    width: 275px;
    height: 400px;
    border-radius: 20px;
    cursor: pointer;
    padding: 5px;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.39) 0px 10px 20px,
        rgba(0, 0, 0, 0.33) 0px 6px 6px;
    background-color: #02354e;
    background: linear-gradient(to bottom,
            #02354e 12rem,
            transparent 1rem,
            transparent 100%);
}

.mentor-text-center {
    text-align: center;
    font-size: 16px;
    font-weight: 350;
}

.mentor-text-container {
    margin-top: 40px;
}

.name-text {
    font-size: 20px;
    text-align: center;
}

.minor {
    margin-top: 5px;
}

.no-minor {
    margin-top: 20px;
}