.today {
    background-image: linear-gradient(#e60d96, #0a87c6);
    height: 350px;
    width: 100vw;
    margin-top: 5px;
}

.today-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    column-gap: 20%;
    padding-top: 30px;
}

.todays-date {
    color: white;
    font-weight: 650;
    font-size: 35px;
}


.date-and-time {
    padding-top: 50px;
}

.meeting-message-button {
    padding-top: 50px;
}

.meeting-today {
    color: white;
    font-weight: 600;
    font-size: 25px
}

.join-meeting-button {
    background-color: white;
    height: 75px;
    width: 250px;
    border-radius: 20px;
    color: black;
    border: none;
    font-weight: 600;
    font-size: 17px;
    box-shadow: rgba(0, 0, 0, 0.39) 0px 20px 40px,
        rgba(0, 0, 0, 0.33) 0px 6px 6px;
}

.welcome-message {
    margin-top: 50px;
    color: black;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.mentor-description {
    color: white;
    font-size: 15px;
    text-align: center;
    padding: 0px 10px;
    margin-top: -10px;
}

.action-items-message {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #717171
}

.action-items-flex {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
}

.action-item {
    border: 1px solid black;
    height: 450px;
    width: 350px;
    border-radius: 20px;
    background-image: linear-gradient(bottom, black, black 40%, transparent 40%, transparent 100%);
    background-image: -webkit-linear-gradient(bottom, black, black 40%, transparent 40%, transparent 100%);
    box-shadow: rgba(0, 0, 0, 0.39) 0px 20px 40px,
        rgba(0, 0, 0, 0.33) 0px 6px 6px;
    cursor: pointer;
}

.action-item-text {
    text-align: center;
    margin-top: 110px;
    font-size: 25px;
    font-weight: 650;
    color: white
}

.action-icon {
    font-size: 100px;
    position: relative;
    left: 50%;
    margin-top: 130px;
    transform: translate(-50%, -50%);
}

.calendar {
    color: blue
}

.book-meeting {
    color: #FF00BF;
}

.money {
    color: #0BDA51
}

.profile {
    color: red;
}

.forum {
    color: darkviolet
}

@media (max-width: 1500px) {
    .today-grid {
        display: grid;
        grid-template-columns: auto;
        justify-content: center;
        padding-top: 0px;
    }

    .todays-date {
        text-align: center;
    }

    .meeting-message-button {
        padding-top: 25px;
    }

    .today {
        height: 475px;
    }

    .join-meeting-button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 25px;
    }

    .meeting-today {
        text-align: center;
    }
}

@media (max-width: 1500px) {
    .today {
        height: 450px;
    }
}

@media (max-width: 1500px) {
    .action-items-flex {
        display: grid;
        grid-template-columns: auto auto;
        margin-top: 30px;
    }
}

@media (max-width: 800px) {
    .action-items-flex {
        display: grid;
        grid-template-columns: auto;
        margin-top: 30px;
    }

    .meeting-today {
        font-size: 23px;
        text-align: center;
    }

    .next-meeting-text {
        font-size: 18px;
    }
}

@media (max-width: 430px) {
    .todays-date {
        font-size: 30px;
    }

    .meeting-today {
        text-align: center;
        padding: 0px 25px;
    }

    .action-item {
        height: 400px;
        width: 300px;
    }

    .next-meeting-text {
        display: none;
    }

    .mentor-description {
        margin-top: -120px;
        padding: 5px;
    }

}