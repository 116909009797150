.mentor-profile {
    height: 1000px
}

.mentor-bio-page {
    overflow: hidden;
}

.mentor-profile-header {
    text-align: center;
    font-size: 25px;
    margin-top: 150px;
    font-weight: 600;
}


.mentor-profile-header-two {
    text-align: center;
    font-size: 25px;
    margin-bottom: -100px;
    font-weight: 600;
}

.edit-flex {
    display: flex;
}

.edit-icon {
    display: relative;
    color: white;
    font-size: 35px;
    margin-left: 150px;
    cursor: pointer;
}

.edit-icon-name {
    display: relative;
    color: black;
    font-size: 25px;
    margin-left: 80px;
    margin-top: 46px;
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
}

.edit-icon-name:hover {
    background-color: whitesmoke;
}

.edit-icon-about {
    color: black;
    font-size: 30px;
    margin-top: 10px;
    right: 10px;
    margin-left: 1030px;
    cursor: pointer;
    border-radius: 50%;
    padding: 15px;
}

.edit-icon-about:hover {
    background-color: whitesmoke;
}

.edit-icon-profile {
    color: black;
    font-size: 30px;
    margin-top: 10px;
    right: 10px;
    margin-left: 930px;
    cursor: pointer;
    border-radius: 50%;
    padding: 15px;
}

.edit-icon-profile:hover {
    background-color: whitesmoke;
}

.edit-icon-experience {
    color: black;
    font-size: 30px;
    margin-top: 10px;
    right: 10px;
    margin-left: 880px;
    cursor: pointer;
    border-radius: 50%;
    padding: 15px;
}

.edit-icon-experience:hover {
    background-color: whitesmoke;
}

.edit-icon-question {
    color: black;
    font-size: 30px;
    margin-top: 5px;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 50%;
    padding: 15px;
}

.edit-icon-question:hover {
    background-color: whitesmoke;
}

.edit-icon-question-center {
    color: black;
    font-size: 30px;
    margin-top: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    border-radius: 50%;
    padding: 15px;
}

.edit-icon-question-center:hover {
    background-color: whitesmoke;
}

.modal-content {
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 60px;
}

.edit-name-modal {
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 700px;
    height: 600px;
    background-color: white;
    z-index: 10;
    margin-top: 5%;
    border-radius: 10px;
    border: 1px solid black;
}


.edit-profile-modal {
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 700px;
    height: 600px;
    padding-bottom: 50px;
    background-color: white;
    z-index: 10;
    margin-top: 5%;
    border-radius: 10px;
    border: 1px solid black;
}


.modal-background {
    filter: blur(10px);
    position: absolute;
    width: 100%;
    height: 100%;
}

.edit-header {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin-top: 20px;
}

.edit-field {
    padding-left: 40px;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: -20px;
    font-weight: 600;
}

.gray {
    background-color: whitesmoke;
}

.headline-input {
    width: 620px;
    padding: 20px;
    border: 2px solid black;
    margin-left: 40px;
    margin-top: 40px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
}

.city-input {
    width: 300px;
    padding: 15px 20px;
    border: 2px solid black;
    margin-left: 40px;
    margin-top: 40px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
}

.select-grade {
    margin-left: 40px;
    margin-top: 40px;
    background-color: white;
    border: 2px solid black;
    font-weight: 400;
    border-radius: 10px;
    width: 200px;
    border: 1px solid black;
}

.grade-state-flex {
    display: flex;
    gap: 20px;
}

.icon-x-mark {
    position: absolute;
    right: 30px;
    top: 25px;
    font-size: 25px;
    cursor: pointer;
    color: red
}

.save-changes {
    margin-left: 40px;
    margin-top: 50px;
    width: fit-content;
    height: 50px;
    padding: 10px 20px;
    background-color: #02354e;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

.save-changes-clicked {
    margin-left: 40px;
    margin-top: 50px;
    width: fit-content;
    height: 50px;
    padding: 10px 20px;
    background-color: #0575ad;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

.cancel-button {
    margin-left: 40px;
    margin-top: 50px;
    width: 130px;
    height: 50px;
    padding: 10px 20px;
    background-color: #AA0000;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

.save-cancel-flex {
    display: flex;
}

.edit-name-error {
    color: #AA0000;
    margin-left: 40px;
    font-size: 20px;
    margin-top: 60px;
    font-weight: 600;
}

.edit-about-text {
    margin-left: 40px;
    width: 600px;
    height: 350px;
    padding: 15px;
    border-radius: 5px;
    margin-top: 40px;
    border: 1px solid black;
}

.edit-profile-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: -50px;
}

.edit-questions-grid {
    display: grid;
    margin-left: 40px;
    grid-template-columns: auto;
    row-gap: 35px;
    margin-top: 50px;
}

.edit-question-input {
    width: 500px;
    height: 50px;
    background-color: white;
    border: 2px solid black;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid black;
}

.edit-question-flex {
    display: flex;
}

.edit-question-ask-me-text {
    margin-top: 12px;
    margin-right: 10px;
}

.experience-modal {
    max-height: 900px;
    overflow-y: scroll;
}

.experience-input-header {
    margin-left: 40px;
    margin-top: 30px;
    margin-bottom: 5px;
    font-size: 15px;
    color: rgb(113, 113, 113);
}

.experience-input {
    width: 600px;
    height: 35px;
    padding: 10px;
    margin-left: 40px;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid black;
}

.experience-textarea {
    margin-left: 40px;
    width: 600px;
    min-height: 100px !important;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
}

.edit-name {
    border: 1px solid black;
}

.start-date-flex {
    display: flex;
    margin-left: 40px;
    gap: 10px
}

.experience-select-date {
    width: 300px;
    height: 40px;
    font-size: 14px;
    border: 1px solid black;
    border-radius: 10px;
}

.experience-currently-in-role {
    margin-left: 41px;
    margin-top: 20px;
    cursor: pointer;
    height: 15px;
    width: 15px;
    accent-color: green;
}

.experience-currently-in-role-text {
    margin-left: 70px;
    color: rgb(101, 101, 101);
    font-size: 16px;
    margin-top: -19px;
}

.experience-date-disabled {
    border: none;
    color: rgba(101, 101, 101, 0.241);
}

.green {
    color: green
}

.edit-profile-success-message {
    margin-top: 120px;
    text-align: center;
    font-size: 25px;
    font-weight: 550;
    color: green
}

.check-circle-icon {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 50px;
    color: green;
    margin-top: 20px;
}

.update-success-modal {
    height: 450px;
}

.close-update-success-button {
    margin-top: 50px;
    height: 50px;
    width: 100px;
    padding: 10px 20px;
    background-color: #02354e;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 1500px) {
    .modal-content {
        max-height: 400px;
    }

    .edit-name-modal {
        height: 600px;
        margin-top: 6.5%;
    }

    .edit-profile-modal {
        height: 600px;
        overflow: hidden;
        margin-top: 6.5%;
    }
}

@media (max-width: 1300px) {
    .edit-icon-about {
        margin-left: 620px;

    }

    .edit-icon-profile {
        margin-left: 595px;
    }


    .edit-icon-experience {
        margin-left: 570px;
    }
}

@media (max-width: 800px) {
    .edit-profile-modal {
        margin-top: 30%;
    }

    .edit-name-modal {
        margin-top: 30%;
    }

    .edit-icon-about {
        margin-left: 280px;

    }

    .edit-icon-profile {
        margin-left: 245px;
    }


    .edit-icon-experience {
        margin-left: 220px;
    }

    .edit-name-modal {
        width: 450px;
    }

    .edit-profile-modal {
        width: 450px;
    }

    .edit-name {
        width: 175px;
    }

    .edit-name-text {
        width: 150px;
        font-size: 16px;
    }

    .headline-input {
        width: 375px;
        font-size: 15px;
    }

    .select-grade {
        width: 150px;
    }

    .edit-about-text {
        width: 380px;
    }

    .edit-question-input {
        width: 260px;
    }

    .experience-input {
        width: 350px;
    }

    .experience-select-date {
        width: 175px;
    }

    .experience-textarea {
        width: 350px;
    }
}

@media (max-width: 430px) {

    .mentor-profile-header {
        font-size: 17px;
    }

    .mentor-profile-header-two {
        font-size: 17px;
        padding: 0px 20px;
    }

    .edit-field {
        margin-left: -15px;
    }

    .edit-icon-about {
        margin-left: 180px;
        margin-top: -2px;
        border-radius: 0%;
    }

    .edit-icon-name {
        border-radius: 0%;
    }

    .edit-icon-profile {
        margin-left: 145px;
        border-radius: 0%;
    }


    .edit-icon-experience {
        margin-left: 120px;
        border-radius: 0%;
    }

    .college-experience {
        margin-top: 20px;
    }

    .edit-name-modal {
        width: 350px;
        margin-top: 30%;
    }

    .edit-profile-modal {
        width: 350px;
        margin-top: 30%;
    }

    .edit-field {
        font-size: 15px;
    }

    .edit-name {
        width: 145px;
    }

    .edit-name-text {
        width: 130px;
        font-size: 15px;
    }

    .headline-input {
        width: 290px;
        font-size: 15px;
        margin-left: 25px;
    }

    .select-grade {
        width: 130px;
        margin-left: 25px;
    }

    .edit-about-text {
        width: 300px;
        margin-left: 20px;
    }

    .edit-question-input {
        width: 200px;
        margin-left: -10px;
        height: 35px;
    }

    .edit-question-ask-me-text {
        font-size: 13.5px;
        margin-right: 15px;
        margin-top: 9px;
    }

    .edit-question-flex {
        margin-left: -10px;
    }

    .experience-input {
        width: 300px;
        margin-left: 25px;
    }

    .start-date-flex {
        margin-left: 25px;
    }

    .experience-select-date {
        width: 150px;
    }

    .experience-currently-in-role {
        margin-left: 30px;
        margin-top: 20px;
    }

    .experience-currently-in-role-text {
        margin-left: 20px;
    }

    .experience-textarea {
        width: 300px;
        margin-left: 25px;
    }

    .save-changes {
        font-size: 16px;
        padding: 0px 12px;
        margin-left: 20px;
    }

    .cancel-button {
        font-size: 16px;
    }

    .experience-input-header {
        margin-left: 25px;
    }


    .city-input {
        width: 250px;
        margin-left: 20px;
    }

    .edit-icon-question-center {
        border-radius: 0%;
    }
}