.email-error-message {
    margin-top: 300px;
    font-size: 20px;
    text-align: center;
}

.error-icon {
    top: 200px;
    font-size: 60px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}

.change-email {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    margin-bottom: 100px;
    border-radius: 10px;
    width: 150px;
    padding: 10px;
    border: none;
    background-color: #02354e;
    color: white;
    font-weight: 600;
    font-size: 18px;
}