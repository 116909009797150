.accept-deny-button-flex {
    display: flex;
    justify-content: center;
    gap: 20px
}

.accept-deny-button-grid {
    display: flex;
    justify-content: center;
    gap: 20px
}

.my-meetings-accept-button {
    background-color: #006A4E;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 50px;
    width: 115px;
    padding: 5px;
    font-weight: 600;
    margin-bottom: -5px;
}

.my-meetings-deny-button {
    background-color: #BA0021;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 50px;
    width: 110px;
    padding: 5px;
    font-weight: 600;
    margin-bottom: 20px;
}

.meeting-accepted-flex {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.meeting-accepted-icon {
    color: green;
    font-size: 25px;
}

@media (max-width: 900px) {
    .meeting-accepted-flex {
        display: none;
    }
}

@media (max-width: 700px) {
    .accept-deny-button-flex {
        gap: 5px
    }
}

@media (max-width: 430px) {
    .my-meetings-accept-button {
        width: 75px;
        font-size: 14px;
    }

    .my-meetings-deny-button {
        width: 75px;
        font-size: 14px;
    }

    .accept-or-decline-text {
        display: none;
    }

    .accept-deny-button-grid {
        display: grid;
        justify-content: center;
    }
}