.split-screen {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: 50vw 50vw;
    margin-bottom: -50px;
    overflow: hidden;
}

.profile-complete {
    background-image: linear-gradient(#e60d96, #0a87c6);
    height: 100vh;
    width: 100vw;
}

.profile-success-message {
    text-align: center;
    position: relative;
    top: 30%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: white;
    font-size: 40px;
    font-weight: 700;
}

.profile-loading-message {
    text-align: center;
    position: relative;
    top: 30%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: white;
    font-size: 30px;
    font-weight: 500;
}

.instructions {
    background-image: linear-gradient(#e60d96, #0a87c6);
}

.actions {
    background-color: white;
    overflow: hidden;
}

.instructions-text {
    color: white;
    font-size: 60px;
    font-weight: 600;
    padding: 50px;
    position: relative;
}

.instructions-text-container {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.qaulifier {
    color: white;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    padding-left: 50px;
}

.text-area {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.inner-grid {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    overflow-x: scroll;
}

.add-a-profile-image {
    width: 280px;
    height: 555px;
    border-radius: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.add-a-profile-image-text {
    text-align: center;
    color: black;
    font-size: 40px;
    font-weight: 600;
}

.image-div {
    width: 275px;
    height: 350px;
    border-radius: 20px;
    cursor: pointer;
    padding: 5px;
    border: 2px solid black;
}

.mentor-img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-top: 70px;
    border: 3px solid white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.upload-photo-button {
    height: 50px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    background-color: #0a87c6;
    border: 1px solid #0a87c6;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
    width: 280px;
}

input[type="file"] {
    display: none;
}

.next-button {
    width: 100px;
    height: 50px;
    background-color: #0a87c6;
    border-radius: 10px;
    border: 2px solid #0a87c6;
    color: white;
    font-weight: 600;
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.next-button:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.back-button {
    width: 100px;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #0a87c6;
    color: #0a87c6;
    font-weight: 600;
    position: absolute;
    bottom: 10px;
    margin-left: 20px;
}

.back-button:hover {
    background-color: #0a87c6;
    color: white;
}

.disabled {
    color: white;
    background-color: gray;
    border: 1px solid gray;
    opacity: .4;
    cursor: not-allowed;
}

.student-form-data {
    position: relative;
    margin-top: 19%;
    height: 68%;
    overflow: scroll;
    padding-bottom: 500px;
}

.what-school {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.what-minor {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.center {
    text-align: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.select-school {
    border: 1px solid black;
    width: 90%;
    border-radius: 10px;
    font-weight: 400;
    color: rgb(37, 35, 35);
    margin-top: -70px;
}

.select-school:focus {
    border: 2px solid #004CC4;
}

.add-school {
    text-align: center;
    margin-top: -10px;
    cursor: pointer;
    font-weight: 500;
}

.flex-school {
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.flex-school:hover {
    color: #004CC4;
}

.plus-icon {
    text-align: left;
    margin-left: 10px;
    margin-top: -5px;
    cursor: pointer;
    color: #004CC4
}

.flex-major {
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin-top: 30px;
}

.flex-major:hover {
    color: #004CC4
}

.flex-major-two {
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.flex-major-two:hover {
    color: #004CC4
}

.add-major {
    text-align: center;
    margin-top: -10px;
    cursor: pointer;
    font-weight: 500;
}

.majors-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
}

.column-spacing {
    column-gap: 20px;
}

.center-text {
    text-align: center;
}

.select-width {
    width: 200px;
}

.involvement-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
    z-index: 10;
}

.involvement-grid-wrapper {
    position: relative;
    margin-top: 19%;
    overflow: auto;
    height: 70%;
    padding-bottom: 500px;
}

.ask-me-grid-wrapper {
    position: relative;
    margin-top: 19%;
    overflow: auto;
    height: 70%;
    padding-bottom: 500px;
}

.involvement-text {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 50px;
    padding: 10px;
}

.student-involvement-box {
    font-size: 17px;
    text-align: center;
    border: 1px solid #004bc46b;
    width: 200px;
    border-radius: 5px;
    color: #02354e;
    font-weight: 600;
    cursor: pointer;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.student-involvement-box:hover {
    background-color: #02354e;
    color: white;
}

.student-involvement-box-selected {
    border: 1px solid #004bc46b;
    max-width: 200px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 17px;
    background-color: #4B9CD3;
}

datalist {
    display: flex;
    justify-content: space-between;
    color: black;
    width: 550px;
}

.range {
    width: 550px;
}

.range-label {
    font-size: 13px;
}

.club-text {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px 10px;
}

.student-involvement-box-selected:hover {
    color: white;
    background-color: #4B9CD3;
}

.control-bar {
    width: 50%;
    height: 90px;
    background-color: white;
    position: absolute;
    bottom: 0px;
    z-index: 100;
}

.progress-bar {
    background-color: rgb(157, 155, 155) !important;
    color: rgb(157, 155, 155) !important;
    border: solid 2px rgb(157, 155, 155) !important;
    height: 5px !important;
    width: 100% !important;
    position: absolute;
    z-index: 100;
}

.progress-bar-start {
    background-color: black;
    color: black;
    border: solid 2px black;
    height: 5px;
    width: 16.7%;
    position: absolute;
    z-index: 20000;
}


.second {
    width: 33%;
}

.third {
    width: 50%;
}

.fourth {
    width: 66%;
}

.fifth {
    width: 85%;
}

.sixth {
    width: 99%;
}


.questions-grid {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 275px;
    justify-content: center;
    row-gap: 100px;
    column-gap: 75px;
    padding-bottom: 500px;
}

.multiple-choice {
    padding-left: 20px;
    margin-top: 5px;
}

.question {
    font-size: 18px;
}

.select-question {
    font-weight: 400;
    border-radius: 10px;
    width: 200px;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.involvement-input {
    width: 195px;
    border-radius: 5px;
    border: none;
    padding-left: 5px;
    height: 30px;
    margin-top: 30px;
}

.ask-me-about-flex {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

input::placeholder {
    font-size: 14px;
}

.ask-me-about-text {
    margin-top: 15px;
    padding-right: 20px;
}

.ask-me-about-input {
    padding-left: 20px;
    width: 500px;
}

.flex-involvement {
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: -26px;
    gap: 45px;
    z-index: 3;
}

.check-icon {
    font-size: 25px;
    color: green;
    cursor: pointer;
    margin-top: -1px;
    margin-left: 7px;
}

.plus-icon-1 {
    font-size: 25px;
    color: #FFC72C;
    cursor: pointer;
    margin-top: -2px;
}

.referral-email {
    border-radius: 5px;
    border: 1px solid black;
    height: 30px;
    width: 200px;
    padding: 10px;
}

.x-icon {
    font-size: 21px;
    color: #AA0000;
    cursor: pointer;
}

.last-page-container {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.intro-text {
    font-size: 27px;
    text-align: center;
    font-weight: 600;
}

.top {
    margin-top: 50px;
}

.ask-me-header {
    margin-top: 20px;
}

.textarea-intro {
    width: 500px;
    height: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    padding: 15px;
    line-height: 30px;
    resize: none;
}

.number-of-chars {
    position: relative;
    text-align: center;
    margin-bottom: -2px;
    margin-left: 360px;
}

.char-number {
    position: relative;
    text-align: center;
    margin-top: 50px;
    margin-bottom: -24px;
}

.double {
    margin-left: 212px;
}

.triple {
    margin-left: 200px;
}


@media (max-width: 2500px) {
    .questions-grid {
        margin-top: 200px;
        grid-template-columns: auto;
        max-height: 850px;
        overflow-y: scroll;
        width: 900px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        border: 1px solid black;
        padding: 10px;
        border-radius: 10px;
        padding-bottom: 500px;
        padding-top: 50px;
    }

    /* ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    } */
}

@media (max-width: 1900px) {
    .questions-grid {
        margin-top: 200px;
        grid-template-columns: auto;
        max-height: 800px;
        overflow-y: scroll;
        width: 800px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        padding-bottom: 500px;
        padding-top: 50px;
    }
}

@media (max-width: 1600px) {
    .questions-grid {
        width: 700px;
        padding-bottom: 500px;
    }
}

@media (max-width: 1500px) {
    .questions-grid {
        margin-top: 120px;
        grid-template-columns: auto;
        max-height: 550px;
        overflow-y: scroll;
        width: 650px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        padding: 20px;
        padding-bottom: 500px;
    }

    .add-a-profile-image-text {
        margin-top: 100px;
    }
}

@media (max-width: 1300px) {
    .questions-grid {
        margin-top: 50px;
        grid-template-columns: auto;
        max-height: 550px;
        overflow-y: scroll;
        width: 650px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        padding: 20px;
        padding-bottom: 100px;
        margin-bottom: 500px;
    }
}

@media (max-width: 1200px) {
    .instructions-text {
        font-size: 40px;
    }

    .ask-me-about-input {
        width: 400px;
        height: 60px;
    }

    .ask-me-about-text {
        margin-top: 5px;
    }

    .ask-me-about-flex {
        display: grid;
        grid-template-columns: auto;
        justify-content: center;
    }

    input::placeholder {
        font-size: 13px;
    }
}

@media (max-width: 1300px) {

    .split-screen {
        display: block;
        overflow-y: scroll;
    }

    .instructions {
        height: 250px;
        margin-top: 75px;
    }

    .instructions-text {
        font-size: 30px;
        text-align: center;
    }

    .qaulifier {
        display: none;
    }

    .add-a-profile-image-text {
        margin-top: 400px;
    }

    .student-form-data {
        margin-bottom: 150px;
        margin-top: 50px;
        max-height: 500px;
        border: 1px solid black;
        padding: 50px;
        width: 90vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .text-area {
        margin-top: 200px;
        margin-bottom: -200px;
    }

    .control-bar {
        width: 100%;
        z-index: 1000;
        position: absolute;
    }

    .involvement-grid-wrapper {
        margin-top: 100px;
        border-radius: 10px;
        border: 1px solid black;
        height: 600px;
        width: 80vw;
        overflow-y: scroll;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 500px;
    }

    .ask-me-grid-wrapper {
        padding: 50px;
        margin-top: 100px;
        border-radius: 10px;
        border: 1px solid black;
        height: 600px;
        width: 80vw;
        overflow-y: scroll;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 500px;
    }

    .involvement-grid {
        grid-template-columns: auto auto;
        overflow: hidden;
    }

    .intro-text {
        margin-top: -100px;
    }

    .ask-me-header {
        margin-top: 20px;
    }

    .textarea-intro {
        height: 400px;
        margin-bottom: 0px;
    }

    .majors-grid {
        grid-template-columns: auto auto;
        row-gap: 20px;
    }

    .last-page-container {
        margin-top: 400px;
    }
}

@media (max-width: 750px) {
    .majors-grid {
        grid-template-columns: auto;
        row-gap: 20px;
    }

    .involvement-grid {
        grid-template-columns: auto;
    }

    .textarea-intro {
        width: 450px;
        height: 400px;
    }

    .number-of-chars {
        margin-left: 330px;
    }

    .char-number {
        padding-right: 25px;
    }

    .questions-grid {
        width: 400px;
    }

    .ask-me-about-input {
        width: 370px;
        height: 60px;
    }
}

@media (max-width: 430px) {

    .student-form-data {
        margin-top: 10%;
        height: 550px;
        width: 375px;
        padding-top: 50px;
        border-radius: 10px;
    }

    .what-school {
        margin-top: -25px;
        font-size: 18px;
    }

    .what-minor {
        font-size: 18px;
        margin-top: -45px;
    }

    .select-school {
        margin-top: -85px;
    }

    .flex-school {
        margin: -15px;
    }

    .control-bar {
        bottom: -50px;
        z-index: 1000;
        position: absolute;
    }

    .instructions-text {
        font-size: 15px;
    }

    .instructions {
        height: 100px;
    }

    .add-a-profile-image-text {
        font-size: 25px;
        margin-top: 250px;
    }

    .mentor-img {
        width: 200px;
        height: 200px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 25px;
    }


    .questions-grid {
        margin-top: 10px;
        grid-template-columns: auto;
        max-height: 500px;
        overflow-y: scroll;
        width: 375px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        padding: 20px;
        padding-bottom: 150px;
        margin-bottom: 0px;
        padding-top: 50px;
    }

    select {
        text-align: center;
    }

    .question {
        max-width: 325px;
        text-align: center;
        margin: 0 auto;
        word-wrap: normal;
        font-size: 15px;
    }

    .select-question {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 10px;
    }

    .multiple-choice {
        position: relative;
        left: 48%;
        transform: translateX(-50%);
        margin-left: 5px;
    }

    .pointer {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .range {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 250px;
    }

    .range-label {
        font-size: 12px;
    }

    .intro-text {
        margin-top: -150px;
        font-size: 20px;
    }

    .ask-me-header {
        margin-top: 20px;
    }

    .textarea-intro {
        width: 370px;
        height: 455px;
    }

    .number-of-chars {
        margin-left: 0px;
    }

    .char-number {
        text-align: center;
        padding: 0px;
        margin-left: 0px;
        margin-right: 160px;
    }

    .involvement-grid-wrapper {
        margin-top: 20px;
        max-height: 500px;
        padding-bottom: 100px;
        width: 375px;
    }

    .ask-me-grid-wrapper {
        margin-top: 20px;
        max-height: 500px;
        padding: 0px;
        padding-bottom: 100px;
        width: 375px;
    }

    .ask-me-about-text {
        text-align: center;
    }

    .ask-me-about-input {
        width: 340px;
    }

    .last-page-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 50px;
    }

    .intro {
        font-size: 20px;
        margin-top: -35px;
    }

    .instructions {
        margin-top: 0px;
    }

    .profile-success-message {
        text-align: center;
        position: relative;
        top: 30%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: white;
        font-size: 30px;
        font-weight: 700;
        padding: 10px;
    }

    .control-bar {
        bottom: 25px;
    }

    .referral-email {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

}