.footer-container {
    height: 350px;
    width: 100%;
    background-color: #02354e;
    clip-path: ellipse(75% 100% at center top);
    margin-top: 50px;
}


.footer-slogan {
    position: relative;
    z-index: 3;
    font-size: 50px;
    color: white;
    text-align: center;
    font-weight: 700;
    top: 50px;
}

.flex {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-buttons {
    position: relative;
    z-index: 3;
    color: white;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
}

.footer-button-1 {
    width: 200px;
    height: 70px;
    background-color: white;
    border-radius: 10px;
    border: none;
    color: #02354e;
    font-weight: 600;
    font-size: 18px;
}

.footer-button-2 {
    width: 200px;
    height: 70px;
    background-color: #02354e;
    border-radius: 10px;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.footer-details {
    position: relative;
    color: black;
    margin-top: 20px;
    text-align: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    column-gap: 200px;
    padding: 0px 50px;
}

.footer-details-header {
    font-weight: 600;
    font-size: 17px;
    text-align: left;
}

.footer-details-list {
    font-weight: 400;
    font-size: 14px;
    margin-top: 25px;
    color: black;
    text-align: left;
    cursor: pointer;
}

.footer-details-list:hover {
    color: #02354e;
}

.footer-horizontal-line {
    width: 90%;
    top: 100px;
    border: 1px solid black;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-top: -80px;
}

.flex-2 {
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.flex-3 {
    display: flex;
    justify-content: left;
    gap: 25px;
}

.flex-4 {
    display: flex;
    justify-content: left;
    gap: 0px;
}

.footer-bottom-bar-container {
    top: 100px;
    position: relative;
    left: 5%;
    width: 90vw;
}

.footer-bottom-bar {
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
}

.footer-bottom-bar:hover {
    color: #02354e;
}

.footer-copyright-notice {
    position: relative;
    left: 5%;
    top: 120px;
    max-width: 90vw;
}

.copyright-notice {
    font-size: 12px;
    font-weight: 300;
}

.social-media-icon {
    font-size: 20px;
    cursor: pointer;
}

.social-media-icon:hover {
    color: #02354e;
}

.copy-right-icon {
    font-size: 10px;
    margin-top: 4.5px;
}

.sponsor-text {
    text-align: center;
    color: black;
    margin-top: 20px;
    font-size: 20px;
    padding: 0px 30px;
}

@media (max-width: 1040px) {
    .footer-buttons {
        top: 50px;
    }

    .footer-details {
        column-gap: 130px;
    }

    .flex-2 {
        gap: 8px;
    }

    .footer-bottom-bar {
        font-size: 10px;
    }

    .social-media-icon {
        font-size: 16px;
        cursor: pointer;
    }
}

@media (max-width: 780px) {
    .footer-buttons {
        top: 50px;
    }

    .footer-details {
        column-gap: 70px;
    }

    .flex-2 {
        gap: 8px;
    }

    .footer-bottom-bar {
        font-size: 8px;
    }

    .social-media-icon {
        font-size: 13px;
        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .footer-button-1 {
        width: 150px;
        height: 50px;
        font-size: 15px;
    }

    .footer-button-2 {
        width: 150px;
        height: 50px;
        font-size: 15px;
    }

    .footer-slogan {
        font-size: 40px;
    }

    .footer-details {
        column-gap: 40px;
    }

    .flex-2 {
        gap: 8px;
    }


    .social-media-icon {
        font-size: 13px;
        cursor: pointer;
    }
}

@media (max-width: 420px) {
    .footer-container {
        height: 250px;
        width: 100%;
        background-color: #02354e;
        clip-path: ellipse(75% 100% at center top);
        margin-top: 50px;
        padding: 5px;
    }

    .footer-buttons {
        top: 20px;
    }

    .footer-button-1 {
        width: 160px;
        height: 60px;
        font-size: 18px;
    }

    .footer-button-2 {
        width: 160px;
        height: 60px;
        font-size: 18px;
    }

    .footer-slogan {
        font-size: 30px;
        top: 20px;
    }

    .footer-details {
        column-gap: 30px;
    }

    .footer-details-header {
        font-size: 13px;
    }

    .footer-details-list {
        font-size: 11px;
    }

    .social-media-icon {
        font-size: 20px;
        cursor: pointer;
        margin-top: -3px;
    }

    .flex-2 {
        display: grid;
        grid-template-columns: auto;
    }

    .footer-bottom-bar {
        font-size: 10px;
    }
}