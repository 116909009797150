.my-navbar {
    width: 100%;
    height: 90px;
    position: absolute;
    top: 0px;
    z-index: 10;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: #02354e;
}

.my-navbar-long {
    width: 100%;
    background-color: white;
    min-height: 100%;
    position: fixed;
    overflow-y: hidden;
    top: 0px;
    z-index: 50;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.navbar-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 310px
}

.navbar-container-authenticated {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 350px
}

.navbar-container-mentor-page {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 950px
}

.navbar-container-left {
    display: flex;
    gap: 40px;
}

.navbar-container-right {
    display: flex;
    gap: 10px;
}

.navbar-container-right-authenticated {
    display: flex;
    gap: 10px;
    padding-right: 60px;
}

.wilt-navbar {
    font-size: 30px;
    font-weight: 800;
    color: white;
    cursor: pointer;
}

.wilt-navbar-hamburger {
    font-size: 30px;
    font-weight: 800;
    color: white;
    cursor: pointer;
}

.wilt-navbar-hamburger-active {
    font-size: 30px;
    font-weight: 800;
    color: #02354e;
    cursor: pointer;
    margin-left: 95px;
    margin-top: 18px;
}


.wilt-navbar-hamburger-container {
    display: grid;
    grid-template-columns: auto;
    margin-top: 20px;
    width: 715px;
    position: relative;
    justify-content: left;
    left: 50%;
    transform: translateX(-50%);
}

.product-navbar {
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 15px;
    margin-top: 11px;
}

.enterprise-navbar {
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 15px;
    margin-top: 11px;
}

.pricing-navbar {
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 15px;
    margin-top: 11px;
}

.about-us-navbar {
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 15px;
    z-index: 10;
    margin-top: 11px;
}


.sign-up-navbar {
    font-weight: 600;
    color: white;
    cursor: pointer;
    width: 160px;
    height: 50px;
    background-color: #02354e;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: -2px;
}

.talk-to-sales-navbar {
    font-weight: 600;
    color: #02354e;
    cursor: pointer;
    width: 170px;
    height: 50px;
    background-color: white;
    border: 1px solid #02354e;
    border-radius: 10px;
    margin-top: -2px;
    font-size: 16px;
}

.circle {
    font-weight: 600;
    background-color: #02354e;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 50%;
    margin-top: -2px;
}

.become-a-mentor-navbar {
    width: 180px;
    height: 40px;
    margin-top: 5px;
    border-radius: 20px;
    background-color: #02354e;
    border: none;
    color: white;
    font-weight: 600;
}


.become-a-mentor-navbar:hover {
    background-color: rgba(0, 0, 0, 0.277);
}

.initials-text {
    text-align: center;
    color: white;
    margin-top: 8px;
    font-size: 20px;
    letter-spacing: .15ch;
    font-weight: 700;
}

.navbar-hamburger {
    position: absolute;
    left: 96px;
    font-weight: 500;
    color: #02354e;
    cursor: pointer;
}

.home {
    top: 130px;
}

.book {
    top: 180px
}

.product {
    top: 230px;
}

.enterprise {
    top: 280px;
}

.pricing {
    top: 330px;
}

.about-us {
    top: 380px;
}

.sign-up {
    top: 430px;
}

.talk-to-sales {
    top: 330px;
}

.add-my-school-navbar-hamburger {
    position: absolute;
    left: 43px;
    top: 225px;
    font-weight: 500;
    color: #02354e;
    cursor: pointer;
}

.product-navbar:hover {
    text-decoration: underline;
}

.enterprise-navbar:hover {
    text-decoration: underline;
}

.pricing-navbar:hover {
    text-decoration: underline;
}

.sign-up-navbar:hover {
    -webkit-transform: scale(0.9);
    transform: scale(1.01);
}

.talk-to-sales-navbar:hover {
    -webkit-transform: scale(0.9);
    transform: scale(.99);
}

.about-us-navbar:hover {
    text-decoration: underline;
}


@media (max-width: 1300px) {
    .navbar-container-authenticated {
        gap: 270px
    }

    .navbar-container-mentor-page {
        gap: 750px
    }

    .navbar-container {
        gap: 310px
    }

    .wilt-navbar-hamburger-active {
        margin-left: 180px;
    }

    .navbar-hamburger {
        margin-left: 90px;
    }
}

@media (max-width: 1200px) {
    .navbar-container-authenticated {
        gap: 135px
    }

    .navbar-container {
        gap: 180px
    }

    .wilt-navbar-hamburger-active {
        margin-left: 60px;
    }

    .navbar-hamburger {
        margin-left: -30px;
    }
}

@media (max-width: 720px) {
    .wilt-navbar-hamburger-container {
        width: 570px;
    }

    .wilt-navbar-hamburger {
        margin-left: -20px;
    }

}

@media (max-width: 610px) {
    .wilt-navbar-hamburger {
        margin-left: 20px;
    }

    .wilt-navbar-hamburger-active {
        margin-left: 20px;
    }

    .navbar-hamburger {
        margin-left: -70px;
    }
}

@media (max-width: 550px) {
    .wilt-navbar-hamburger {
        margin-left: 50px;
    }
}

@media (max-width: 430px) {
    .wilt-navbar-hamburger {
        margin-left: 105px;
    }

    .my-navbar {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .my-navbar-long {
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

@media (max-width: 200px) {
    .wilt-navbar-hamburger-container {
        display: none;
    }

}







/* Styles For account modal */
.modal-style-user {
    position: absolute;
    width: 350px;
    height: 450px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    z-index: 10;
    margin-top: 10px;
}

.modal-style-mentor {
    position: absolute;
    width: 350px;
    height: 450px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    z-index: 10;
    margin-top: 50px;
    right: 150px
}

.account-icon-in-modal {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: blueviolet;
}

.initials {
    color: white;
    text-align: center;
    font-size: 25px;
    letter-spacing: .15ch;
    font-weight: 600;
    position: relative;
    top: 10px;
}

.username {
    text-align: center;
    font-weight: bolder;
    color: black;
    margin-top: 15px;
}

.email {
    text-align: center;
    color: black;
    margin-top: -15px;
    font-size: 15px;
}

.manage-account-button {
    background-color: white;
    border: none;
    color: black;
    padding: 5px 50px;
    text-align: center;
    display: inline-block;
    margin: 4px 2px;
    border-radius: 16px;
    border: 1px solid black;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.manage-account-button:hover {
    background-color: whitesmoke;
}

.sign-out-button {
    background-color: white;
    border: none;
    color: black;
    padding: 5px 50px;
    text-align: center;
    display: inline-block;
    margin: 4px 2px;
    border: 1px solid black;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
}

.sign-out-button:hover {
    background-color: whitesmoke;
}

.close-modal {
    background-color: white;
    border: none;
    font-size: 25px;
    float: right;
    border-radius: 10px;
}

.close-modal:hover {
    opacity: .5;
}

@media (max-width: 2700px) {
    .modal-style-mentor {
        margin-right: 18vw;
    }
}

@media (max-width: 2500px) {
    .modal-style-mentor {
        margin-right: 12vw;
    }
}

@media (max-width: 2300px) {
    .modal-style-mentor {
        margin-right: 6vw;
    }
}

@media (max-width: 1800px) {
    .modal-style-mentor {
        margin-right: 3vw;
    }
}

@media (max-width: 1500px) {
    .modal-style-mentor {
        margin-right: 0vw;
    }
}