.forum-container {
    margin-top: 20px;
    margin-bottom: 150px;
}

.forum-header-text {
    margin-top: 150px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.04ch;
}