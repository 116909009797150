@media (max-width: 1200px) {
    .text {
        max-width: 400px;
        font-size: 18px;
    }
}

@media (max-width: 430px) {
    .text {
        max-width: 300px;
        font-size: 18px;
    }
}

.terms-and-conditions-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 18px;
}

.terms-and-conditions-text {
    max-width: 700px;
    font-size: 18px;
    line-height: 3ch;
    padding: 30px
}

.terms-and-conditions-header {
    font-size: 50px;
    justify-content: center;
    padding: 30px
}