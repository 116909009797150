.accept-or-decline-flex {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    gap: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.accept-or-decline-button {
    padding: 20px;
    border: none;
    border-radius: 10px;
    font-weight: 700;
    font-size: 22px;
    width: 200px;
    color: white
}

.accept {
    background-color: #00573F;
}

.decline {
    background-color: #EF0107;
}

.cancel-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    column-gap: 100px;
}

.cancel-meeting-button {
    padding: 20px 30px;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    color: white;
    font-size: 20px;
}

.proposed-meetings-grid {
    display: grid;
    justify-content: center;
    row-gap: 20px;
    margin-top: 30px;
    margin-bottom: 40px;
}

.meeting-date-confirmation-text {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    background-color: black;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: -15px;
    margin-bottom: 30px;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.accepted-meeting-flex {
    display: flex;
    justify-content: center;
    margin-bottom: -20px;
}

.accepted-meeting-check {
    margin-top: 42px;
    margin-left: 10px;
}

.need-to-cancel-text {
    text-align: center;
    padding: 10px 20px;
    margin-top: 30px;
    font-size: 20px;
}

.meeting-declined {
    color: #FF033E
}

.no-meeting-error {
    text-align: center;
    font-size: 20px;
    padding: 20px;
    margin-top: 100px;
}

.not-available-button {
    padding: 10px 0px;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    font-weight: 650;
}

@media (max-width: 900px) {
    .accept-or-decline-flex {
        grid-template-columns: auto;
        gap: 75px;
        margin-top: 50px;
    }

    .meeting-booked-extra-long {
        height: 1050px !important;
    }

    .cancel-grid {
        column-gap: 30px;
    }
}

.gray {
    background-color: gray;
}

.no-click {
    cursor: not-allowed;
}

@media (max-width: 650px) {
    .meeting-booked-extra-long {
        height: 1350px !important;
    }

    .accepted-meeting-flex {
        display: grid;
        grid-template-columns: auto;
        gap: 0px;
    }

    .accepted-meeting-check {
        margin-top: 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 430px) {
    .meeting-booked-extra-long {
        height: 1400px !important;
    }

    .cancel-meeting-button {
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    .meeting-booked-text {
        font-size: 20px;
    }

    .meeting-date-confirmation-text {
        font-size: 17px;
        width: 300px;
    }

    .icon-x-mark {
        display: none;
    }
}