.homepage-container {
    background-color: #02354e;
    width: 100%;
    height: 670px;
    position: absolute;
}

.entre-icon {
    margin-left: 30px;
}

.lang-minor {
    margin-left: 15px;
}

.health-minor {
    margin-left: -5px;
}

.arch-icon {
    margin-left: 25px;
}

.kin-icon {
    margin-left: 10px;
}

.health-icon {
    margin-left: 15px;
}

.homepage-top-container {
    display: grid;
    grid-template-columns: 665px 550px;
    position: relative;
    margin-top: 100px;
    justify-content: center;
    z-index: 3;
    column-gap: 15px;
}

.homepage-content-container {
    display: grid;
    justify-content: center;
    padding-top: 20px;
    width: 100%;
}

.header-text {
    font-size: 60px;
    color: white;
    font-weight: 700;
}

.slogan-text {
    font-size: 19px;
    color: white;
    font-weight: 450;
}

.meeting-forum-flex {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 100px;
    position: relative;
    z-index: 3;
}

.homepage-tab {
    font-size: 25px;
    font-weight: 600;
    border: 1px solid black;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
}

.homepage-tab-selected {
    font-size: 25px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    background-color: #02354e;
    color: white
}

.get-started-button {
    width: 200px;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: none;
    padding: 0px 20px;
    color: #02354e;
    font-size: 17px;
    font-weight: 550;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin-top: 50px;
}

.sign-up-button {
    width: 210px;
    height: 63px;
    background-color: #923892;
    border-radius: 5px;
    border: none;
    padding: 0px 20px;
    color: white;
    font-size: 18px;
    font-weight: 550;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin-top: 50px;
    margin-left: 10px;
}

.mentor {
    color: #ecb22e;
}

.demo-video {
    height: 975px;
    width: 680px;
    margin-top: -300px;
    z-index: -50;
}

.mentor-text {
    color: white;
    margin-top: 40px;
    font-size: 18px;
}

.become-a-mentor {
    display: flex;
    gap: 15px;
}

.become-a-mentor-button {
    width: 180px;
    height: 40px;
    margin-top: 34px;
    border-radius: 20px;
    background-color: #02354e;
    border: none;
    color: white;
    font-weight: 600;
}


.become-a-mentor-button:hover {
    background-color: rgba(0, 0, 0, 0.277);
}

.mentors-container {
    position: absolute;
    top: 600px;
    width: 100vw;
    display: block
}

.mentor-header-text {
    text-align: center;
    font-weight: 450;
    font-size: 30px;
    color: black;
    padding: 20px 50px 10px 10px;
}

.select-mentor-instruction {
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
    font-weight: 700;
}

.mentor-header-container {
    display: flex;
    justify-content: center;
    width: 550px;
    position: relative;
    top: 5px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;

}

.filter {
    margin-left: 0px;
    font-size: 25px;
    color: black;
    font-weight: 800;
    cursor: pointer;
    border-left: 1px solid black;
    padding-left: 60px;
    width: 170px;
}

.select {
    border: none;
    cursor: pointer;
    width: 150px;
    position: absolute;
    background: none;
    margin-top: 15px;

}

select {
    padding: 10px;
    padding-right: 30px;
    color: black;
    font-weight: 700;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

.flex {
    display: flex;
    justify-content: center;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.filter-header {
    position: sticky;
    top: 0px;
    background-color: white;
    width: 100vw;
    height: 222px;
    z-index: 10;
    left: 0px;
    right: 0px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 0px,
        rgba(0, 0, 0, 0.2) 0px 3px 3px;
    display: block
}

.outer-filter-container {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 1295px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    padding-top: 20px;
}

.filter-container {
    display: flex;
    justify-content: space-evenly;
    width: 1295px;
    margin: 0 auto;
}

.mentors-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    column-gap: 30px;
    row-gap: 75px;
    margin-top: 50px;
}

.mentor-grid-image {
    width: 275px;
    height: 350px;
    border-radius: 20px;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid black;
}

.mentor-name {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 650;
    margin-left: 5px;
}

.mentor-bio {
    font-size: 16px;
    font-weight: 400;
    margin-left: 5px;
    opacity: .7;
    margin-top: -10px;
}

.mentor-cost {
    font-size: 16px;
    font-weight: 400;
    margin-left: 5px;
    opacity: 1;
    margin-top: -10px;
}

.dollar-amount {
    font-weight: 700;
}

.header-container {
    max-width: 1300px;
    width: 90vw;
    height: 200px;
    background-color: white;
    border-radius: 10px;
    margin-top: 80px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.icon {
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.select-icon {
    height: 35px;
    width: 35px;
    cursor: pointer;
    margin-top: 27px;
    margin-left: 70px;
}

.engineering-icon {
    padding-left: 20px;
}

.poli-sci-icon {
    padding-left: 7px;
}

.pre-med-icon {
    padding-left: 10px;
}

.business-icon {
    padding-left: 11px;
}

.aerospace-icon {
    padding-left: 20px;
}

.nursing-icon {
    padding-left: 10px;
}

.education-icon {
    padding-left: 15px;
}

.filter-icon-selected {
    border-bottom: 2px solid black;
    height: 75px;
}

.filter-icon:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.319);
    height: 75px;
}

.filter-icon-text {
    text-align: center;
    font-weight: 600;
    opacity: .6;
    cursor: pointer;
}

.filter-icon-text-selected {
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.allminac-large {
    text-align: center;
    font-size: 65px;
    font-weight: 800;
    color: #02354e;
    letter-spacing: .1rem;
}

.slogan {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    color: #0071c5;
    margin-top: -20px;
}

.allminac-description {
    text-align: center;
    color: black;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400;

}

.search-container-text {
    font-size: 23px;
    color: black;
    font-weight: 600;
    margin-top: 70px;
    position: relative;
    margin-left: 15px;
}

.searchbox-container {
    max-width: 1300px;
    width: 90vw;
    height: 350px;
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.inner-searchbox-container {
    width: 820px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.from {
    font-size: 20px;
}

@media (max-width: 1300px) {
    .demo-video {
        height: 925px;
        width: 630px;
    }

    .homepage-top-container {
        grid-template-columns: 600px 550px;
        column-gap: 0px;
        margin-left: 0px;
    }

    .header-text {
        font-size: 50px;
    }

    .slogan-text {
        font-size: 16px;
    }

    .mentors-grid {
        grid-template-columns: auto auto auto;
        column-gap: 50px;
    }

    .mentor-grid-image {
        width: 300px;
        height: 400px;
    }

    .outer-filter-container {
        width: 1000px;
    }

    .filter-container {
        width: 1350px;
        margin-left: -55px;
    }
}

@media (max-width: 1200px) {
    .demo-video {
        height: 850px;
        width: 580px;
    }

    .homepage-top-container {
        grid-template-columns: 495px 525px;
        column-gap: 0px;
        margin-left: 0px;
    }

    .header-text {
        font-size: 45px;
    }

    .slogan-text {
        font-size: 14px;
    }
}

@media (max-width: 1070px) {
    .demo-video {
        height: 375px;
        width: 700px;
        margin-top: 20px;
    }

    .homepage-top-container {
        grid-template-columns: auto;
        margin-left: 0px;
    }

    .header-text {
        text-align: center;
        font-size: 65px;
    }

    .slogan-text {
        text-align: center;
        font-size: 16px;
    }

    .get-started-button {
        position: relative;
        left: 35%;
        transform: translateX(-50%);
    }

    .sign-up-button {
        position: relative;
        left: 36%;
        transform: translateX(-50%);
    }

    .mentor-text {
        position: relative;
        left: 35%;
        transform: translateX(-50%);
    }

    .become-a-mentor-button {
        position: relative;
        left: 36%;
        transform: translateX(-50%);
    }

    .homepage-container {
        height: 1000px;
    }

    .mentors-container {
        position: absolute;
        top: 1000px;
        width: 100vw;
        display: block
    }
}

@media (max-width: 1030px) {
    .mentors-grid {
        grid-template-columns: auto auto;
        column-gap: 50px;
    }

    .mentor-grid-image {
        width: 300px;
        height: 350px;
    }

    .outer-filter-container {
        width: 752px;
    }

    .homepage-tab {
        font-size: 20px;
    }

    .homepage-tab-selected {
        font-size: 20px;
    }

    .meeting-forum-flex {
        gap: 30px;
    }
}

@media (max-width: 790px) {
    .mentors-grid {
        grid-template-columns: auto auto;
        column-gap: 50px;
    }

    .mentor-header-container {
        width: 400px;
    }

    .mentor-header-text {
        font-size: 20px;
        padding-left: 20px;
    }

    .filter {
        font-size: 20px;
        padding-left: 50px;
        margin-left: -30px;
    }

    .select-icon {
        margin-top: 23px;
        margin-left: 15px;
    }

    .outer-filter-container {
        width: 405px;
    }

    .filter-header {
        height: 207.2px;

    }

}

@media (max-width: 730px) {
    .mentors-grid {
        grid-template-columns: auto;
        column-gap: 50px;
    }

    .header-text {
        font-size: 55px;
    }

    .slogan-text {
        font-size: 16px;
    }
}

@media (max-width: 590px) {
    .header-text {
        font-size: 47px;
    }

    .slogan-text {
        font-size: 15px;
    }

}

@media (max-width: 420px) {
    .outer-filter-container {
        width: 355px;
    }

    .filter-container {
        width: 1350px;
        margin-left: -55px;
    }

    .filter-header {
        height: 183px;
        position: sticky;
    }

    .icon {
        width: 25px;
        height: 25px;
    }

    .filter-icon-text {
        font-size: 14px;
    }

    .filter-icon-text-selected {
        font-size: 14px;
    }

    .filter-icon-selected {
        height: 55px;
    }

    .engineering-icon {
        margin-left: 7px;
    }

    .poli-sci-icon {
        margin-left: 5px;
    }

    .business-icon {
        margin-left: 7px;
    }

    .psych-icon {
        margin-left: 10px;
    }

    .chem-icon {
        margin-left: 7px;
    }

    .mentor-header-container {
        width: 340px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 2px,
            rgba(0, 0, 0, 0.23) 0px 3px 3px;

    }

    .mentor-header-text {
        font-size: 14.5px;
        padding: 25px 20px 10px 30px;
    }

    .filter {
        font-size: 17px;
        padding-left: 50px;
        margin-left: 0px;
    }


    .mentor-grid-image {
        width: 340px;
        height: 340px;
    }

    .mentors-grid {
        margin-top: 30px;
        row-gap: 30px;
    }

    .select-icon {
        width: 20px;
        height: 20px;
        margin-top: 27px;
        margin-left: 0px;
    }
}

@media (max-width: 360px) {
    .get-started-button {
        width: 115px;
        height: 50px;
        font-size: 13px;
        margin-left: 27px;
    }

    .sign-up-button {
        width: 110px;
        height: 50px;
        font-size: 14px;
    }
}

@media (max-width: 260px) {
    .get-started-button {
        width: 80px;
        height: 50px;
        font-size: 11px;
        margin-left: 27px;
    }

    .sign-up-button {
        width: 80px;
        height: 50px;
        font-size: 12px;
    }

    .header-text {
        font-size: 10px;
        margin-left: 30px;
    }
}

@media (max-width: 200px) {
    .get-started-button {
        width: 50px;
        height: 50px;
        font-size: 7px;
        margin-left: 27px;
    }

    .sign-up-button {
        width: 50px;
        height: 50px;
        font-size: 7px;
    }
}

@media (max-width: 900px) {
    .search-container-text {
        margin-left: 110px;
    }
}

@media (max-width: 670px) {
    .search-container-text {
        margin-left: 190px;
    }
}

@media (max-width: 610px) {
    .header-container {
        height: 250px
    }

    .allminac-large {
        font-size: 55px;
    }

    .allminac-description {
        font-size: 15px;
    }

    .search-container-text {
        margin-left: 190px;
    }

}

@media (max-width: 550px) {
    .search-container-text {
        margin-left: 215px;
    }

    .header-text {
        font-size: 43px;
    }

    .slogan-text {
        font-size: 13.5px;
    }

    .demo-video {
        width: 600px;
        height: 350px;
        margin-top: 50px;
    }
}

@media (max-width: 430px) {

    .mentor-grid-image {
        width: 240px;
        height: 240px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .mentor-name {
        text-align: left;
    }

    .mentor-bio {
        text-align: left;
    }

    .mentor-grid {
        grid-template-columns: auto;
        justify-content: center;
    }

    .header-text {
        font-size: 36px;
        font-weight: 800;
        line-height: 1.8ch;
    }

    .slogan-text {
        font-size: 15px;
        font-weight: 700;
    }

    .get-started-button {
        display: none;
    }

    .sign-up-button {
        width: 95vw;
        position: relative;
        left: 48%;
        transform: translateX(-50%);
        margin-top: 30px;
        height: 55px
    }

    .mentor-text {
        font-size: 16px;
    }

    .become-a-mentor {
        gap: 0px;
        margin-left: -30px;
    }

    .become-a-mentor-button {
        margin-top: 32px;
    }

    .demo-video {
        display: none;
    }

    .homepage-container {
        height: 550px;
        background-color: #00344C;
    }

    .mentors-container {
        margin-top: -450px;
    }

    .header-container {
        width: 95vw;
        margin-top: 70px;
        padding: 2px;
    }

    .allminac-large {
        font-size: 50px;
        margin-top: 20px;
    }

    .allminac-description {
        font-size: 12px;
    }

    .searchbox-container {
        width: 95vw;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .search-container-text {
        margin-left: 242px;
        font-size: 20px;
    }

    .homepage-tab {
        font-size: 18px;
    }

    .homepage-tab-selected {
        font-size: 18px;
    }

    .meeting-forum-flex {
        gap: 15px;
        margin-top: 40px;
    }
}