.mentor-dashboard-tabs {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 100px;
    gap: 50px;
    cursor: pointer;
}

.tab-name {
    font-weight: 600;
    font-size: 20px;
    color: #717171;
    padding: 10px 20px;
    border-radius: 20px;
}

.tab-name:hover {
    background-color: #E0E0E0;
}

.selected-tab {
    font-weight: 600;
    font-size: 20px;
    color: black;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 20px;
}

.mentor-tabs-hr {
    margin: -5px;
    width: 100%;
}