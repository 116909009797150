.mentor-profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-top: 15px;
    border: 3px solid white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.mentor-profile-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    padding-top: 150px;
    column-gap: 20px;
}


.profile-line-separator {
    top: -160px;
    position: relative;
    margin-left: 10px;
}

.profile-line-separator-2 {
    top: -340px;
    position: relative;
    margin-left: 10px;
}

.mentor-profile-image-container {
    width: 375px;
    height: 635px;
    border-radius: 20px;
    border: 1px solid #02354e;
    margin-top: 50px;
    background: linear-gradient(to bottom,
            #02354e 12rem,
            transparent 1rem,
            transparent 100%);

}

.mentor-profile-name {
    text-align: left;
    margin-left: 30px;
    font-size: 25px;
    font-weight: bold;
    margin-top: 50px;
}

.about-card-text {
    margin-left: 30px;
    padding: 15px 15px 15px 4px;
}

.mentor-profile-details-major {
    font-size: 16px;
    font-weight: 500;
    width: 350px;
    text-align: left;
    margin-left: 30px;
    margin-top: -10px;
    max-width: fit-content;
}

.mentor-profile-buttons-flex {
    display: flex;
    justify-content: left;
    margin-left: 30px;
    gap: 20px;
    margin-top: 40px;
}

.mentor-profile-message-button {
    border: none;
    border-radius: 10px;
    background-color: #02354e;
    padding: 5px 10px;
    color: white;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.mentor-profile-bookmark-button {
    border: none;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: white;
    color: #02354e;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.messages-icon {
    width: 20px;
}

.bookmark-icon {
    width: 16px;
    color: #02354e;
    margin-top: 2px;
}

.mentor-profile-button-bookmark-icon-flex {
    display: flex;
    justify-content: center;
    border: 2px solid #02354e;
    border-radius: 10px;
    background-color: white;
    padding: 1px 10px;
    color: #02354e;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.184);
    cursor: pointer;
}

.coming-soon-flex {
    display: flex;
    justify-content: left;
    gap: 50px;
    margin-left: 40px;
}

.mentor-rating-flex {
    display: flex;
    justify-content: left;
    margin-top: 30px;
    gap: 10px;
    margin-left: 30px;
}

.mentor-rating {
    margin-top: 8px;
    color: gold;
    font-weight: 800;
    font-size: 18px;
}

.star-icon {
    color: gold;
    width: 20px;
}

.testimonial {
    margin-left: 30px;
    font-weight: 250;
    max-width: 300px;
}

.number-of-ratings {
    margin-top: 7px;
    color: black;
    opacity: .4;
    font-weight: 300;
    font-size: 18px;
}

.mentor-profile-button-message-icon-flex {
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 10px;
    background-color: #02354e;
    padding: 5px 10px;
    color: white;
    font-size: 15px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px,
        rgba(0, 0, 0, 0.23) 0px 3px 3px;
    cursor: pointer;
}

.mentor-profile-details-year-state {
    font-size: 16px;
    font-weight: 500;
    width: 350px;
    text-align: left;
    margin-left: 30px;
    margin-top: -10px;
    color: rgba(0, 0, 0, 0.613);
}

.cards {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    margin-top: 50px;
}

.about-card {
    width: 1200px;
    height: fit-content;
    padding-bottom: 20px;
    padding-right: 10px;
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 10px,
        rgba(0, 0, 0, 0.23) 1px 2px 2px;
}

.college-experience {
    margin-top: 30px;
    height: fit-content;
    padding-bottom: 50px;
}

.college-profile-card {
    width: 1200px;
    height: fit-content;
    padding-bottom: 20px;
    padding-right: 10px;
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 1px 10px,
        rgba(0, 0, 0, 0.23) 1px 2px 2px;
    margin-top: -20px;
}

.student-profile-grid-container {
    display: grid;
    grid-template-columns: 200px auto auto;
    justify-content: left;
    margin-left: 30px;
    column-gap: 200px;
    row-gap: 50px;
    grid-template-rows: auto auto;

}

.club-headers {
    margin-left: 30px;
    font-weight: 700;
    margin-top: 20px;
    font-size: 20px;
}

.club-title {
    margin-left: 31px;
    font-weight: 400;
    font-size: 15px;
    margin-top: -15px;
}

.club-date {
    margin-left: 31px;
    font-weight: 300;
    font-size: 15px;
    margin-top: -15px;
}

.club-description {
    margin-left: 31px;
    font-weight: 400;
    font-size: 15px;
    max-width: 700px;
    white-space: pre-line;
    line-height: 2.5ch;
}

.clubs-line-separator {
    margin-top: 30px;
    margin-bottom: 30px;
    opacity: .15;
}

.profile-flex-container {
    display: flex;
    justify-content: left;
    margin-top: 30px;
}

.profile-text-school {
    margin-left: 50px;
}

.profile-text-major {
    margin-left: 50px;
}

.profile-text-minor {
    margin-left: 50px;
}

.school-icon {
    color: cornflowerblue
}

.major-icon {
    color: cornflowerblue
}

.minor-icon {
    color: cornflowerblue
}

.profile-text-flex {
    display: flex;
}

.profile-header {
    font-size: 18px;
    padding-left: 10px;
    margin-top: 10px;
    font-weight: 600;
    color: cornflowerblue
}

.profile-2-a {
    color: lightsalmon
}

.profile-2-b {
    color: lightsalmon
}

.statistics-container {
    display: grid;
    justify-content: center;
    margin-top: 30px;
    grid-template-columns: auto auto auto auto auto;
}

.about-card-header {
    font-size: 25px;
    color: black;
    font-weight: 600;
    padding-left: 30px;
    padding-top: 10px;
}

.high-school-icon {
    color: lightsalmon
}

.graduate-icon {
    color: lightsalmon
}

.one-statistic {
    width: 227px;
    height: 300px;
    border-radius: 20px;
    margin-left: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px,
        rgba(0, 0, 0, 0.23) 0px 3px 3px;
}

.stat-explanation {
    font-size: 18px;
    color: black;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    padding: 10px;
}

.backpack-icon {
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 40px;
}


.stat-label-workload {
    text-align: center;
    font-weight: 800;
    font-size: 24px;
    margin-top: 30px;
    color: #00688B;
}

.blue {
    color: #00688B;
}

.pink {
    color: #FF69B4;
}

.purple {
    color: #355E3B;
}

.red {
    color: red;
}

.orange {
    color: #ba55d3;
}

.border-blue {
    border: 2px solid #00688B;
}

.border-pink {
    border: 2px solid #FF69B4;
}

.border-purple {
    border: 2px solid #355E3B;
}

.border-red {
    border: 2px solid red;
}

.border-orange {
    border: 2px solid #ba55d3;
}

.review-request-header {
    height: 50px;
    border-bottom: 1px solid black;
}

.review-request-footer {
    height: 50px;
    border-top: 1px solid black;
    position: absolute;
    bottom: 30px;
    width: 100%;
}

.meeting-request-flex {
    display: flex;
    justify-content: left;
    margin-left: 30px;
    gap: 50px;
    margin-top: 10px;
}

.submit-meeting-request-button1 {
    padding: 10px 15px;
    border-radius: 10px;
    color: white;
    background-color: green;
    border: none;
    font-size: 20px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
        rgba(0, 0, 0, 0.2) 3px 6px 9px;
}

.cancel-meeting-request-button {
    padding: 10px 15px;
    border-radius: 10px;
    color: white;
    background-color: red;
    border: none;
    font-size: 20px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
        rgba(0, 0, 0, 0.2) 3px 6px 9px;

}

.meeting-request-details {
    margin-left: 30px;
    overflow: scroll;
    margin-top: 10px;
    max-height: 550px;
    padding-right: 10px;
    padding-bottom: 30px;
}

.meeting-request-header {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
}

.meeting-details-text {
    font-size: 16px;
    margin-top: -10px;
}

.meeting-request-subheader {
    font-weight: 500;
}

.review-request-header-text {
    font-size: 23px;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
}

.faq-questions {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: blue
}

@media (max-width: 1300px) {
    .mentor-profile-grid {
        grid-template-columns: auto;
        width: 100vw;
    }

    .profile-line-separator {
        top: -160px;
    }

    .mentor-profile-image-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .about-card {
        width: 800px;
    }

    .student-profile-grid-container {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
    }

    .college-profile-card {
        width: 800px;
    }

    .pf-minor {
        color: lightsalmon
    }

    .minor-icon {
        color: lightsalmon
    }

    .high-school-icon {
        color: saddlebrown
    }

    .profile-2-b {
        color: saddlebrown
    }

    .graduate-icon {
        color: saddlebrown
    }

    .statistics-container {
        grid-template-columns: auto auto auto;
        row-gap: 20px;
    }

    .one-statistic {
        width: 250px;
    }

    .profile-line-separator-2 {
        top: -370px;
        position: relative;
        margin-left: 10px;
    }
}

@media (max-width: 800px) {
    .about-card {
        width: 450px;
        margin-top: -50px;
        padding-bottom: 0px;
    }

    .statistics-container {
        grid-template-columns: auto auto;
    }

    .student-profile-grid-container {
        grid-template-columns: auto;
        grid-template-rows: 175px 175px 175px 175px 175px 175px;
        row-gap: 0px;
    }

    .college-profile-card {
        width: 450px;
    }

    .one-statistic {
        width: 220px;
    }

    .profile-line-separator {
        top: -900px;
    }

    .profile-line-separator-2 {
        top: -710px;
    }

    .profile-line-separator-3 {
        top: -570px;
        position: relative;
        margin-left: 10px;
    }

    .profile-line-separator-4 {
        top: -430px;
        position: relative;
        margin-left: 10px;
    }

    .profile-line-separator-5 {
        top: -270px;
        position: relative;
        margin-left: 10px;
    }

    .college-experience {
        margin-top: 30px;
        height: fit-content;
        padding-bottom: 50px;
    }
}

@media (max-width: 430px) {

    .mentor-profile-grid {
        grid-template-columns: auto;
        width: 100vw;
    }

    .mentor-profile-image-container {
        width: 350px;
        margin-top: -20px;
    }

    .about-card {
        width: 350px;
        margin-top: -50px;
    }

    .college-profile-card {
        width: 350px;
    }

    .student-profile-grid-container {
        row-gap: 20px;
    }

    .statistics-container {
        grid-template-columns: auto;
    }

    .profile-line-separator {
        top: -1000px;
    }

    .profile-line-separator-2 {
        top: -810px;
    }

    .profile-line-separator-3 {
        top: -630px;
        position: relative;
        margin-left: 10px;
    }

    .profile-line-separator-4 {
        top: -430px;
        position: relative;
        margin-left: 10px;
    }

    .profile-line-separator-5 {
        top: -270px;
        position: relative;
        margin-left: 10px;
    }

    .profile-text-school {
        margin-left: 35px;
    }

    .profile-text-major {
        margin-left: 35px;
    }

    .profile-text-minor {
        margin-left: 35px;
    }

    .school-icon {
        margin-top: 8px;
    }

    .graduate-icon {
        margin-top: 8px;
    }

    .major-icon {
        margin-top: 8px;
    }

    .minor-icon {
        margin-top: 10px;
    }

    .high-school-icon {
        margin-top: 8px;
    }

    .star-icon {
        margin-top: 8px;
    }

    .submit-meeting-request-button1 {
        padding: 10px 10px;
        border-radius: 10px;
        color: white;
        background-color: green;
        border: none;
        font-size: 18px;
        font-weight: 600;
        box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
            rgba(0, 0, 0, 0.2) 3px 6px 9px;
    }

    .cancel-meeting-request-button {
        padding: 10px 10px;
        border-radius: 10px;
        color: white;
        background-color: red;
        border: none;
        font-size: 18px;
        font-weight: 600;
        box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
            rgba(0, 0, 0, 0.2) 3px 6px 9px;

    }


    .meeting-request-flex {
        display: flex;
        justify-content: left;
        margin-left: 20px;
        gap: 10px;
        margin-top: 15px;
    }
}


/* .stat-bar-workload {
    width: 240px;
    height: 20px;
    border: 2px solid #00688B;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    display: block;
    border-radius: 15px;
    background: -webkit-linear-gradient(left, #00688B 30%, #ffffff 30%);
}

.percentage-workload {
    text-align: center;
    font-weight: bold;
    color: #00688B;
    margin-top: 20px;
    font-size: 23px;
} */