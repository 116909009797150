.user-profile {
    height: 650px;
}

.user-profile-container {
    margin-top: 150px;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    row-gap: 50px;
}

.personal-information-header {
    font-weight: 700;
    font-size: 30px;
    color: rgb(72, 72, 72);
}

.personal-information-box {
    border-bottom: 2px solid rgba(113, 113, 113, 0.172);
    width: 600px;
}

.personal-information-text {
    font-weight: 350;
    font-size: 20px;
    color: rgb(72, 72, 72);
}

.personal-information-edit {
    font-weight: 500;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer
}

.personal-information-value {
    font-weight: 350;
    font-size: 16px;
    color: rgb(113, 113, 113);
    padding-bottom: 20px;
}

.personal-information-flex {
    display: flex;
    justify-content: space-between;
}

.name-container-personal-info {
    margin-top: 40px;
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.569);
    height: 70px;
    border-radius: 10px;
}

.active-border-info {
    border: 2px solid black;
    border-radius: 10px;
    border: block;
}

.info-boxes-flex {
    display: flex;
    justify-content: center;
    gap: 20px
}

.personal-info-save {
    width: 100px;
    height: 50px;
    background-color: #02354e;
    color: white;
    font-weight: 700;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.personal-info-save-disabled {
    width: 100px;
    height: 50px;
    background-color: #02354e;
    color: white;
    font-weight: 700;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    opacity: .2;
    cursor: not-allowed !important;
}

.personal-info-save-submitting {
    width: 100px;
    height: 50px;
    background-color: #087fba;
    color: white;
    font-weight: 700;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.active-border-red-profile {
    border: 2px solid rgb(217, 57, 0);
    border-radius: 10px;
    border: block;
}

.name-input-info {
    margin-left: 12px;
    position: relative;
    top: -10px;
    font-size: 18px;
    color: rgb(37, 35, 35);
    border: none;
}

@media (max-width: 700px) {
    .personal-information-box {
        border-bottom: 2px solid rgba(113, 113, 113, 0.172);
        width: 400px;
    }

    .info-boxes-flex {
        gap: 10px
    }

    .name-container-personal-info {
        width: 200px;
    }

    .name-input-info {
        width: 180px;
    }
}

@media (max-width: 430px) {
    .personal-information-box {
        border-bottom: 2px solid rgba(113, 113, 113, 0.172);
        width: 300px;
    }

    .info-boxes-flex {
        gap: 10px
    }

    .name-container-personal-info {
        width: 150px;
    }

    .name-input-info {
        width: 120px;
        height: 30px
    }
}