.user-meetings-container {
    padding-top: 150px;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    row-gap: 40px;
    max-height: 800px;
    overflow-y: scroll;
}

.my-meetings-header-text {
    font-weight: 700;
    font-size: 30px;
    color: rgb(72, 72, 72);
}

.meeting-box {
    width: 600px;
    border-bottom: 2px solid rgba(113, 113, 113, 0.172);
}

.my-meetings-join-button {
    background-color: #087fba;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 50px;
    width: 100px;
    padding: 5px;
    font-weight: 600;
    margin-bottom: 10px;
}

.my-meetings-cancel-button {
    background-color: #F40009;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 50px;
    font-weight: 600;
    margin-bottom: 20px;
    width: 100px;
    padding: 5px;
}

.my-meetings-cancel-button-long {
    background-color: #F40009;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 50px;
    min-width: 100px;
    padding: 10px;
    font-weight: 600;
    margin-bottom: 20px;
}

.refresh-warning {
    text-align: center;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: -10px;
    padding: 0px 5px;
}

.my-meetings-cancel-button-long-being-cancelled {
    background-color: orange;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 50px;
    min-width: 100px;
    padding: 10px;
    font-weight: 600;
    margin-bottom: 20px;
}

.my-meetings-date-button {
    background-color: black;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    min-height: 50px;
    padding: 5px;
    font-weight: 600;
    margin-bottom: 30px;
    width: 350px;
    cursor: pointer;
}

.proposed-meeting-date {
    margin-top: -5px;
}


.join-cancel-flex {
    display: flex;
    gap: 20px
}

.join-cancel-grid {
    display: flex;
    gap: 20px
}


.my-meetings-buttons-flex {
    display: flex;
    justify-content: left;
    gap: 20px
}

.meeting-with-name {
    color: rgb(55, 54, 54);
    font-weight: 700px;
    font-size: 18px;
}

.no-meetings-yet {
    font-size: 20px;
}

.no-meetings-book-meeting-button {
    border-radius: 10px;
    background-color: #087fba;
    border: none;
    color: white;
    font-weight: 700;
    padding: 10px;
    width: 200px;
    font-size: 18px;
}

.meeting-cancelled-success {
    font-size: 20px;
    color: green;
    font-weight: 600;
    display: flex;
    gap: 10px;
}

.cannot-join-meeting-yet {
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 550px;
    height: 250px;
    padding-bottom: 50px;
    background-color: white;
    z-index: 10;
    margin-top: 5%;
    border-radius: 10px;
    border: 1px solid black;
}

.cannot-join-meeting-header-text {
    text-align: center;
    padding: 20px 40px;
    font-size: 23px;
    color: #02354e;
    font-weight: 650;
}

.cannot-join-meeting-sub-text {
    text-align: center;
    padding: 20px;
    font-size: 20px;
    color: black;
    font-weight: 400;
}

.meeting-status-flex {
    display: flex;
    gap: 150px
}

.blur-background {
    filter: blur(10px);
    width: 100%;
    height: 100%;
}

@media (max-width: 700px) {
    .meeting-box {
        width: 400px;
    }

    .my-meetings-date-button {
        font-size: 15px;
        width: 300px;
        padding: 10px;
    }

    .my-meetings-buttons-flex {
        gap: 10px
    }

    .my-meetings-join-button {
        width: 75px;
    }

    .cannot-join-meeting-yet {
        width: 450px;
    }

    .cannot-join-meeting-header-text {
        padding: 20px 50px;
    }

    .meeting-with-name {
        font-size: 16px;
        margin-left: 10px;
    }
}

@media (max-width: 430px) {

    .cannot-join-meeting-yet {
        width: 350px;
    }

    .cannot-join-meeting-header-text {
        padding: 20px 50px;
    }

    .my-meetings-header-text {
        text-align: center;
    }

    .meeting-box {
        width: 350px;
    }

    .my-meetings-join-button {
        width: 75px;
    }

    .my-meetings-cancel-button {
        width: 75px;
    }

    .my-meetings-cancel-button-long {
        font-size: 18px;
        padding: 5px;
    }


    .my-meetings-buttons-flex {
        gap: 10px
    }

    .cannot-join-meeting-header-text {
        text-align: center;
        padding: 20px 40px;
        font-size: 20px;
        color: #02354e;
        font-weight: 650;
    }

    .cannot-join-meeting-sub-text {
        text-align: center;
        padding: 20px;
        font-size: 17px;
        color: black;
        font-weight: 400;
    }

    .my-meetings-date-button {
        width: 175px;
    }

    .large-date {
        width: 275px;
    }

    .proposed-meeting-date {
        margin-top: -5px;
        font-size: 14px;
    }

    .join-cancel-flex {
        display: flex;
        gap: 5px
    }

    .join-cancel-grid {
        display: grid;
        grid-template-columns: auto;
        gap: 0px
    }
}