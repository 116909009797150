.calendar-border {
    height: 640px;
    width: 800px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.184);
    margin-top: 50px;
    background-color: whitesmoke;
    padding: 20px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.calendar-month-year {
    margin-left: 30px;
    font-size: 18px;
}


.review-request-modal {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 600px;
    height: 700px;
    z-index: 50;
    background-color: white;
    margin-top: 200px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
        rgba(0, 0, 0, 0.2) 3px 6px 9px;
}

.calendar-month-year-blue {
    margin-left: 2px;
    font-size: 18px;
    color: #08a4fc;
    cursor: pointer;
    font-weight: 500;
}

.selected-day {
    font-size: 18px;
    top: -63px;
    position: relative;
    left: 510px;
    margin-bottom: -25px;
}

.calendar-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    padding: 20px;
    text-align: center;
    width: 500px;
    margin-top: 5px;
}

.calendar-title {
    text-align: left;
    font-size: 20px;
    font-weight: 450;
    margin-top: 10px;
    margin-left: 27px;
}

.calendar-day {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-left: -15px;
}

.day {
    font-weight: 500;
    color: gray;
    background-color: rgba(128, 128, 128, 0.200);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    height: 50px;
    width: 50px;
}

.active {
    color: #08a4fc;
    font-weight: 800;
    cursor: pointer;
    background-color: #08a3fc38;
}

.added {
    color: white;
    font-weight: 800;
    cursor: pointer;
    background-color: green;
    cursor: default;
}

.selected {
    background-color: #08a4fc;
    color: white;
    font-size: 18px;
}


.arrow-right {
    color: gray;
    font-size: 20px;
    top: -38px;
    position: relative;
    left: 430px;
}

.arrow-left {
    color: gray;
    font-size: 20px;
    top: -38px;
    position: relative;
    left: 350px;
}

.arrow-active {
    color: #08a4fc;
    cursor: pointer;
}

.available-times {
    position: absolute;
    margin-left: 500px;
    margin-top: 20px;
    height: 440px;
    overflow-y: scroll;
}


.no-scroll {
    overflow-y: hidden;
}

.available-time {
    border: 1px solid #08a4fc;
    width: 250px;
    text-align: center;
    height: 50px;
    padding-top: 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.calendar-instructions {
    margin-top: -50px;
    margin-left: 30px;
    font-size: 20px;
    font-weight: 700;
    color: #08a4fc
}

.available-time:hover {
    color: white;
    background-color: #08a4fc;
}

.confirm-meeting-button {
    border: none;
    width: 250px;
    text-align: center;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    background-color: green;
    color: white;
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.19) 3px 6px 6px,
        rgba(0, 0, 0, 0.2) 3px 3px 3px;
}

.confirm-meeting-button-disabled {
    border: none;
    width: 250px;
    text-align: center;
    height: 50px;
    border-radius: 10px;
    cursor: not-allowed !important;
    background-color: orange;
    color: white;
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
}

.submit-meeting-request-button {
    border: none;
    text-align: center;
    height: 40px;
    padding: 5px 30px;
    border-radius: 10px;
    cursor: pointer;
    background-color: green;
    color: white;
    font-weight: 600;
    font-size: 17px;
    box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
        rgba(0, 0, 0, 0.2) 3px 6px 9px;
}

.meeting-details {
    font-size: 20px;
    font-weight: 700;
}

.added-meetings {
    margin-top: 2px;
    color: #08a4fc;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
}

.added-meeting-flex {
    display: flex;
    gap: 15px;
}

.deselect-day-icon {
    color: red;
    margin-top: 15px;
    font-size: 30px;
    cursor: pointer;
}

.meeting-day {
    background-color: #08a4fc;
    border-radius: 10px;
    padding: 5px 10px;
    color: white;
    font-weight: 600;
    min-width: 220px;
}

.meeting-time {
    background-color: #08a4fc;
    border-radius: 10px;
    padding: 5px 10px;
    color: white;
    font-weight: 600;
}

.meeting-proposal-text {
    font-weight: 350;
    color: #08a4fc;
}

.exclamation-icon {
    font-size: 25px;
    color: #08a4fc;
    cursor: pointer;
    margin-top: -1px;
    margin-left: 7px;
    cursor: default;
}

.proposed-meeting-header {
    font-weight: 550;
}

.meeting-flex {
    display: flex;
    gap: 5px
}

.check-icon-default-cursor {
    font-size: 25px;
    color: green;
    margin-top: -1px;
    margin-left: 7px;
}

.meeting-details-date {
    font-size: 16px;
    margin-top: 10px;
    background-color: #08a4fc;
    border-radius: 10px;
    height: 40px;
    padding: 7px 10px;
    color: white;
    font-weight: 600;
    text-align: center;
}

.meeting-details-time {
    font-size: 16px;
    margin-top: 15px;
    background-color: #08a4fc;
    border-radius: 10px;
    height: 40px;
    padding-top: 7px;
    color: white;
    font-weight: 600;
    text-align: center;
}

.no-availability-message {
    max-width: 250px;
    line-height: 2.5ch;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #08a4fc;
}

.select-meeting-instructions {
    max-width: 250px;
    line-height: 2.0ch;
    text-align: left;
    font-size: 17px;
    font-weight: 350;
    color: black;
    margin-top: -15px;
}

.book-meeting-faq {
    color: #08a4fc;
    font-size: 14px;
    cursor: pointer;
}

.no-availability-clarification-text {
    max-width: 250px;
    font-size: 15px;
    margin-top: 10px;
    opacity: .9;
}

.notified-about-meeting-request {
    color: green;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 45px;
    margin-bottom: 30px;
}

.interested-button {
    border: none;
    background-color: #02354e;
    border-radius: 10px;
    height: 100px;
    width: 260px;
    padding: 5px;
    color: white;
    font-weight: 650;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.19) 5px 10px 20px,
        rgba(0, 0, 0, 0.23) 3px 6px 6px;
    overflow: none;
}

.interested-button:hover {
    -webkit-transform: scale(0.99);
    transform: scale(0.99);
}

.meeting-details-notice {
    width: 220px;
    padding-left: 5px;
    font-size: 14px;
    padding-top: 10px;
    color: rgb(113, 113, 113);
    text-align: left;
}

.meeting-details-error {
    width: 220px;
    padding-left: 5px;
    font-size: 14px;
    padding-top: 10px;
    color: rgb(113, 113, 113);
    text-align: left;
    margin-bottom: -40px;
}

.get-more-info-link {
    color: #08a4fc;
    text-decoration: underline;
    cursor: pointer;
}

.back-icon {
    font-size: 22px;
    color: #08a4fc;
    margin-top: 2px;
}

.all-times {
    color: #08a4fc;
}

.flex-left {
    display: flex;
    justify-content: left;
    gap: 10px;
    cursor: pointer;
    padding-left: 5px;
}

@media (max-width: 800px) {
    .calendar-border {
        height: 1100px;
        width: 450px;
        padding: 5px;
        margin-bottom: 50px;
    }

    .calendar-container {
        width: 450px;
        column-gap: 7px;
    }

    .day {
        height: 40px;
        width: 40px;
    }

    .calendar-day {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        margin-left: -5px;
    }

    .arrow-right {
        font-size: 20px;
        top: -38px;
        position: relative;
        left: 395px;
        cursor: pointer;
    }

    .arrow-left {
        font-size: 20px;
        top: -38px;
        position: relative;
        left: 280px;
        cursor: pointer;
    }

    .available-times {
        position: absolute;
        margin-left: 120px;
        margin-top: 410px;
        height: 500px;
    }

    .selected-day {
        top: 380px;
        position: relative;
        text-align: left;
        left: 120px
    }

    .calendar-title {
        margin-left: 22px;
    }

    .calendar-month-year {
        margin-left: 23px;
    }
}

@media (max-width: 430px) {
    .calendar-border {
        height: 1100px;
        width: 360px;
        padding: 5px;
        margin-bottom: 50px;
    }

    .calendar-container {
        width: 100px;
        column-gap: 7px;
    }

    .day {
        height: 40px;
        width: 40px;
    }

    .calendar-day {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        margin-left: -5px;
    }

    .arrow-right {
        font-size: 20px;
        top: -38px;
        position: relative;
        left: 315px;
        cursor: pointer;
    }

    .arrow-left {
        font-size: 20px;
        top: -38px;
        position: relative;
        left: 230px;
        cursor: pointer;
    }

    .available-times {
        position: absolute;
        margin-left: 55px;
        margin-top: 430px;
        height: 450px
    }

    .selected-day {
        font-size: 18px;
        left: -16px;
        margin-left: 70px;
        top: 405px;
    }

    .calendar-title {
        margin-left: 22px;
    }

    .calendar-month-year {
        margin-left: 23px;
    }

    .review-request-modal {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 350px;
        height: 700px;
        z-index: 50;
        background-color: white;
        margin-top: 1200px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.19) 2px 4px 6px,
            rgba(0, 0, 0, 0.2) 3px 6px 9px;
    }

    .review-request-header-text {
        font-size: 19px !important;
    }
}