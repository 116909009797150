.meeting-booked-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    margin-top: 150px;
    width: 750px;
    height: 850px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(250, 249, 249);
}

.meeting-booked-flex {
    display: flex;
    justify-content: center;
    gap: 20px
}

.meeting-booked-icon {
    font-size: 35px;
    margin-top: 5px;
    color: green
}

.accept-meeting-text {
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
}

.proposed-meeting-time {
    background-color: black;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
    width: 400px;
    text-align: center;
    border-radius: 10px;
    margin-top: 35px;
}

.meeting-booked-container-short {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    margin-top: 150px;
    width: 750px;
    height: 550px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(250, 249, 249);
}

.meeting-booked-text {
    text-align: center;
    font-size: 28px;
    color: black;
    font-weight: 600;
}

.date-time-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    column-gap: 200px;
    margin-top: 50px;
}

.date {
    font-size: 22px;
}

.date-text {
    text-align: left;
    background-color: white;
    font-size: 20px;
    padding: 5px 5px;
    border-radius: 5px;
    color: #0c356c;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #0c356c;
}

.time {
    font-size: 22px;
}

.how-to-join-text {
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 700;
}

.join-meeting-button-user {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    width: 300px;
    padding: 15px 0px;
    font-size: 25px;
    border-radius: 10px;
    color: white;
    background-color: #0c356c;
    font-weight: 550;
    border: none;
    margin-top: 10px;
}

.join-meeting-button-user:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.join-meeting-text {
    margin-top: 95px;
    text-align: center;
    font-size: 12px;
    color: rgb(113, 113, 113);
}

.common-questions {
    margin-left: 50px;
    margin-top: 100px;
}

.faq {
    font-size: 15px;
    font-weight: 600;
}

.faq-button {
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    background-color: #0c356c;
    color: white;
    padding: 5px 15px;
    margin-top: -5px;
}

.pre-meeting-questions-container {
    margin-top: 30px;
}

.pre-meeting-questions-explanation {
    text-align: center;
    padding: 0px 60px;
    margin-top: 30px;
}

.submit-question {
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 700;
}

.submit-question-input {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 610px;
    margin-top: 30px;
    padding: 5px 10px;
}

.add-another-question {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 40px;
    border: 2px solid #0c356c;
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    color: #0c356c;
    font-weight: 600;
}

.submit-pre-meeting-questions {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    border: none;
    border-radius: 10px;
    background-color: #0c356c;
    padding: 10px;
    font-size: 22px;
    color: white;
    font-weight: 600;
}

.common-question {
    font-size: 15px;
    color: #1877F2;
    cursor: pointer;
}

.common-question:hover {
    color: #0c356c;
}

.pre-meeting-questions-success {
    text-align: center;
    color: green;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 600;
}

.warning-text {
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
}

.warning-explanation {
    padding: 20px;
    font-size: 16px;
    font-weight: 350;
    margin-top: -55px;
}

.warning-second {
    margin-top: -30px;
}

.warning-modal {
    margin-top: -20px !important;
}

.reminders-header {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.reminders-text {
    font-size: 16px;
    font-weight: 350;
    text-align: left;
    padding: 10px 20px;
}

.proposed-meeting-container {
    display: grid;
    justify-content: center;
    margin-bottom: -50px
}


@media (max-width: 900px) {
    .meeting-booked-container {
        width: 600px;
        height: 900px
    }

    .date-time-grid {
        column-gap: 50px;
    }

    .submit-question-input {
        width: 550px;
    }

    .proposed-meeting-time {
        width: 350px;
    }

    .meeting-booked-flex {
        display: grid;
        justify-content: center;
        grid-template-columns: auto;
    }

    .meeting-booked-icon {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -10px;
    }

    .meeting-booked-container-short {
        width: 600px;
        height: 650px
    }
}

@media (max-width: 650px) {
    .meeting-booked-container {
        width: 400px;
        height: 1100px
    }

    .meeting-booked-long {
        height: 1200px !important;
    }

    .date-time-grid {
        grid-template-columns: auto;
    }

    .common-questions {
        margin-top: 100px;
    }

    .date-text {
        font-size: 18px;
    }

    .how-to-join-text {
        margin-top: 70px;
    }

    .submit-question-input {
        width: 350px;
    }

    .pre-meeting-questions-explanation {
        padding: 0px 20px;
    }


    .meeting-booked-container-short {
        width: 400px;
        height: 800px
    }
}

@media (max-width: 430px) {
    .meeting-booked-container {
        width: 350px;
        height: 1150px
    }

    .meeting-booked-long {
        height: 1275px !important;
    }

    .date-time-grid {
        grid-template-columns: auto;
    }

    .common-questions {
        margin-top: 50px;
    }

    .date-text {
        font-size: 18px;
    }

    .how-to-join-text {
        margin-top: 70px;
    }

    .pre-meeting-questions-explanation {
        padding: 0px 5px;
    }

    .submit-question-input {
        width: 300px;
    }

    .proposed-meeting-time {
        width: 300px;
    }

    .proposed-meeting-container {
        margin-bottom: 30px
    }

    .meeting-booked-container-short {
        width: 350px;
        height: 850px
    }
}