.loading-card-container {
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;
}

.loading-card {
    width: 275px;
    height: 350px;
    border-radius: 20px;
    object-fit: cover;
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.392);

}

.line {
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.392);
    margin-left: 5px;
}

.name {
    width: 100px;
}

.major {
    width: 180px
}

.clubs {
    width: 240px;
}