.calendar-page-container {
    display: grid;
    grid-template-columns: auto 350px;
    height: calc(100vh - 160px);
    overflow-y: visible;
}

.mentor-calendar-container {
    margin-top: 20px;
    width: 100%;
}

.fixed-month-year-weekday {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 2;
    padding-top: 30px;
}

.calendar-month {
    font-size: 30px;
    color: rgb(34, 34, 34);
    font-weight: 700;
    margin-left: 50px;
    padding-bottom: 20px;
}

.current-day-container {
    margin-top: 5px;
    border: 1px solid #cccaca;
    min-height: 100%;
}

.weekdays {
    display: flex;
    justify-content: center;
    gap: 15%;
    font-size: 13px;
    color: #767474;
    padding-left: 75px;
    padding-right: 75px;
}

.weekday-line {
    margin-top: -18px;
}

.mentor-calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    margin-top: -16px;
    margin-bottom: 320px;
}

.month-year-in-calendar {
    padding-left: 50px;
    font-size: 22px;
    font-weight: 550;
    padding-top: 100px;
    white-space: nowrap;
}

.calendar-box {
    height: 175px;
    border: 1px solid #cccaca;
    border-left: none;
    cursor: pointer;
}

.calendar-box:hover {
    border: 1px solid black;
}

.left-border {
    border-left: 1px solid #cccaca;
}

.mentor-calendar-day {
    position: relative;
    top: 30px;
    padding-left: 30px;
    color: #767474;
}

.mentor-calendar-day-booked {
    position: relative;
    left: 30px;
    top: 30px;
    color: white;
    font-weight: 600;
}

.blank {
    border: none;
    cursor: default;
}

.not-available {
    cursor: not-allowed;
}

.day-selected {
    border: 2px solid black;
    cursor: pointer;
    height: 175px;
}

.current-day-selected {
    font-size: 25px;
    text-align: left;
    position: sticky;
    padding-top: 20px;
    top: 20px;
    font-weight: 500;
    padding-left: 20px;
    color: rgb(34, 34, 34)
}

.current-day-selected-dropdown {
    font-size: 28px;
    text-align: left;
    padding-top: 20px;
    top: 20px;
    font-weight: 500;
    padding-left: 60px;
    color: rgb(34, 34, 34)
}

.current-day-line {
    top: 128px;
    position: sticky;
    margin-top: 85px;
}

.select-availability {
    top: 150px;
    position: sticky;
    margin-top: 10px;
    padding-left: 20px;
    text-align: left;
    font-weight: 500;
    color: rgb(34, 34, 34);
    font-size: 18px;
}

.select-availability-dropdown {
    top: 150px;
    margin-top: 10px;
    padding-left: 60px;
    text-align: left;
    font-weight: 500;
    color: rgb(34, 34, 34);
    font-size: 18px;
}

.select-availability-instructions {
    position: sticky;
    text-align: left;
    font-size: 13px;
    top: 180px;
    padding-left: 20px;
    color: rgb(113, 113, 113);
    font-weight: 400;
}

.select-availability-instructions-dropdown {
    text-align: left;
    font-size: 13px;
    top: 180px;
    padding-left: 60px;
    color: rgb(113, 113, 113);
    font-weight: 400;
}

.available-time-grid {
    display: grid;
    grid-template-columns: auto;
    position: sticky;
    top: 240px;
    justify-content: left;
    padding-left: 20px;
    height: 500px;
    overflow-y: scroll;
}

.available-time-grid-dropdown {
    display: grid;
    grid-template-columns: auto;
    top: 240px;
    justify-content: left;
    padding-left: 60px;
    height: 350px;
    overflow-y: scroll;
}

.time-button {
    height: 50px;
    width: 300px;
    background-color: white;
    border: 1px solid #7674746d;
    border-radius: 10px;
    margin-top: 20px;
}

.time-button:hover {
    border: 1px solid #02354e;

}

.time-button-selected {
    height: 50px;
    width: 300px;
    background-color: #08a4fc;
    color: white;
    border-radius: 10px;
    margin-top: 20px;
    border: none
}

.time-button-booked {
    height: 50px;
    width: 300px;
    background-color: #1DB954;
    color: white;
    border-radius: 10px;
    margin-top: 20px;
    border: none;
    cursor: not-allowed !important
}

.booked-box {
    background-color: #1DB954;
}

.time-button-disabled {
    height: 50px;
    width: 300px;
    background-color: rgb(207, 203, 203);
    color: white;
    border-radius: 10px;
    margin-top: 20px;
    border: none;
    cursor: not-allowed !important;
}

.save-availability-button {
    position: sticky;
    top: 800px;
    background-color: #08a4fc;
    width: 300px;
    height: 60px;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    font-size: 22px;
    margin-top: 50px;
    margin-left: 20px;
}

.save-availability-button-dropdown {
    background-color: #08a4fc;
    width: 300px;
    height: 60px;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    font-size: 22px;
    margin-top: 0px;
    margin-left: 60px;
}

.availability-button-clicked {
    background-color: orange;
}

.save-availability-instructions {
    position: sticky;
    text-align: left;
    font-size: 13px;
    top: 870px;
    padding-left: 20px;
    color: rgb(113, 113, 113);
    font-weight: 400;
    margin-top: 10px;
}

.save-availability-instructions-dropdown {
    text-align: left;
    font-size: 13px;
    top: 870px;
    padding-left: 62px;
    color: rgb(113, 113, 113);
    font-weight: 400;
    margin-top: 10px;
}

.calendar-horizontal-line {
    color: rgb(190, 188, 188)
}

.button-disabled {
    cursor: not-allowed !important;
    background-color: rgba(128, 128, 128, 0.335);
}

.availability-saved-container {
    position: sticky;
    text-align: left;
    font-size: 20px;
    top: 925px;
    padding-left: 20px;
    color: green;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.availability-saved-container-dropdown {
    text-align: left;
    font-size: 20px;
    padding-left: 60px;
    color: green;
    font-weight: 600;
    margin-top: -10px;
    display: flex;
    gap: 10px;
}

.availability-saved {
    text-align: left;
    font-size: 20px;
    color: green;
    font-weight: 600;
    margin-top: 10px;
}

.circle-check-icon {
    margin-top: 14px;
}

.available-border {
    border: 2px solid #08a4fc;
}

.day-selected-overlay {
    width: 100%;
    background-color: white;
    min-height: 100%;
    position: fixed;
    overflow-y: hidden;
    top: 0px;
    z-index: 50;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

}

.x-icon-calendar {
    color: black;
    font-size: 20px;
    margin-left: 45px;
    margin-top: 10px;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 50%;
}

.x-icon-calendar:hover {
    border: none;
    background-color: rgb(234, 230, 230)
}

@media (max-width: 2000px) {
    .available-time-grid {
        height: 300px;
    }

    .save-availability-button {
        top: 560px;
        margin-top: 20px;
    }

    .save-availability-instructions {
        top: 625px;
        margin-top: 10px;
        padding-left: 25px;
    }

    .availability-saved-container {
        top: 675px;
    }
}


@media (max-width: 1200px) {
    .calendar-box {
        height: 140px;
    }

    .day-selected {
        height: 140px
    }
}

@media (max-width: 1100px) {
    .calendar-box {
        height: 105px;
    }


    .day-selected {
        height: 105px
    }
}

@media (max-width: 700px) {
    .calendar-box {
        height: 85px;
    }


    .day-selected {
        height: 85px
    }
}

@media (max-width: 1000px) {
    .calendar-page-container {
        grid-template-columns: auto;
    }
}

@media (max-width: 430px) {
    .x-icon-calendar {
        margin-left: 20px;
    }

    .current-day-selected-dropdown {
        padding-left: 35px;
    }

    .select-availability-dropdown {
        padding-left: 36px;
    }

    .select-availability-instructions-dropdown {
        padding-left: 36px;
    }

    .available-time-grid-dropdown {
        padding-left: 35px;
    }

    .save-availability-button-dropdown {
        margin-left: 35px;
    }

    .save-availability-instructions-dropdown {
        padding-left: 36px;
    }

    .availability-saved-container-dropdown {
        padding-left: 35px;
    }
}