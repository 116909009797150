.about-page-container {
    background-color: #02354e;
    margin-top: 90px;
    height: 500px;
}

.about-page-grid {
    padding-top: 40px;
    display: grid;
    justify-content: center;
    column-gap: 200px;
    grid-template-columns: auto auto;
    margin-left: 175px;
}

.about-page-type {
    font-size: 70px;
    font-weight: 700;
    border-bottom: 3px solid black;
    width: 1000px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: relative;
}


.about-page-slogan {
    font-size: 70px;
    font-weight: 700;
    max-width: 400px;
    overflow: wrap;
    color: white;
}

.lower-slogan-text {
    margin-left: 10px;
}

.about-page-slogan-2 {
    font-size: 70px;
    font-weight: 700;
    max-width: 800px;
    overflow: wrap;
    color: white;
}

.bold-letter {
    color: #ecb22e;
}

.about-page-sub-slogan {
    color: white;
    font-size: 22px;
    padding-top: 30px;
    font-weight: 350;
}

.about-page-get-started-button {
    margin-top: 20px;
    width: 310px;
    height: 63px;
    background-color: #923892;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 550;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.about-page-container-2 {
    margin-top: 100px;
}

.about-page-slogan-center {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}

.about-page-go-to-umich {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin-top: 75px;
}

.about-page-sentence-center {
    font-size: 70px;
    font-weight: 700;
    border-bottom: 3px solid black;
    width: 1000px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.about-page-container-3 {
    background-color: #f4ede4;
    margin-top: 200px;
    margin-bottom: -100px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
}

.school-tours-top-left {
    margin-right: 500px;
    padding-top: 100px;
}

.school-tours-top-right {
    margin-top: 200px;
    padding-top: 100px;
}

.school-tours-bottom-left {
    margin-top: 100px;
    margin-bottom: 200px;
}

.about-page-schools-tours-header {
    font-size: 35px;
    font-weight: 600;
}

.about-page-school-tours-text {
    text-align: left;
    font-size: 18px;
    max-width: 600px;
    line-height: 3ch;
    -webkit-font-smoothing: antialiased;
    color: black;
}


@media (max-width: 1900px) {
    .about-page-grid {
        padding-top: 10px;
        display: grid;
        justify-content: center;
        column-gap: 215px;
        grid-template-columns: auto auto;
        margin-left: 110px;
    }

    .about-page-container {
        background-color: #02354e;
        margin-top: 90px;
        height: 400px;
    }

    .about-page-container-2 {
        margin-top: 50px;
    }

    .about-page-container-3 {
        background-color: #f4ede4;
        margin-top: 100px;
        margin-bottom: -100px;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
    }

    .school-tours-top-left {
        margin-right: 50px;
        padding-top: 100px;
    }
}

@media (max-width: 1370px) {
    .about-page-sub-slogan {
        display: none;
    }

    .about-page-slogan {
        font-size: 50px
    }

    .about-page-slogan-2 {
        font-size: 60px
    }

    .school-tours-top-left {
        margin-left: 50px;
        padding-top: 100px;
    }

    .school-tours-bottom-left {
        margin-left: 50px;
    }

    .school-tours-top-right {
        margin-right: 50px;
    }
}

@media (max-width: 1070px) {

    .about-page-container {
        height: 400px;
    }

    .about-page-sub-slogan {
        display: none;
    }

    .about-page-slogan-2 {
        font-size: 60px;
        text-align: center;
    }

    .about-page-slogan {
        font-size: 55px;
        text-align: center;
        margin-left: 0px;
        max-width: 100vw;
    }

    .about-page-grid {
        padding-top: 0px;
        justify-content: center;
        grid-template-columns: auto;
        margin-left: 0px;
    }

    .about-page-get-started-button {
        display: none;
    }

    .about-page-container-3 {
        grid-template-columns: auto;
    }

    .school-tours-top-left {
        margin-left: 0px;
        padding-top: 100px;
        margin-right: 0px;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .school-tours-bottom-left {
        margin-left: 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    .school-tours-top-right {
        margin-right: 0px;
        margin-top: 0px;
        margin-left: 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

    }

    .about-page-school-tours-text {
        text-align: center;
        max-width: 100%;
        padding: 0px 100px;

    }

    .about-page-type {
        font-size: 50px;
        font-weight: 700;
        border-bottom: 3px solid black;
        width: 800px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        position: relative;
    }
}

@media (max-width: 700px) {
    .about-page-type {
        font-size: 35px;
        width: 500px;
    }

    .about-page-slogan-2 {
        font-size: 45px;
    }

    .about-page-school-tours-text {
        padding: 0px 20px;

    }
}

@media (max-width: 430px) {
    .about-page-type {
        font-size: 27px;
        width: 380px;
    }

    .about-page-slogan-2 {
        font-size: 38px;
    }

    .about-page-school-tours-text {
        padding: 0px 30px;

    }

    .about-page-schools-tours-header {
        padding: 0px 10px;
    }
}