.payment-container {
    margin-top: 200px;
    display: grid;
    justify-content: center;
    margin-bottom: 200px;
}

.payment-title {
    font-size: 30px;
    font-weight: 650;
}

.payment-title-2 {
    font-size: 27px;
    font-weight: 500;
    margin-top: 30px;
}

.venmo-username {
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: -10px;
}

.payment-title-3 {
    font-size: 27px;
    font-weight: 500;
    margin-top: 50px;
}

.referral-explanation {
    font-size: 16;
    font-weight: 400;
}

.referral-flex {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 30px;
}

.referral-column-value {
    font-weight: 400;
    position: relative;
    left: 50%;
    font-size: 18px;
    transform: translateX(-50%);
}

.referral-hr {
    margin-top: 50px;
}

.referral-column-title {
    font-weight: 650;
}

.venmo-input {
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 20px;
}

.venmo-input-no-edit {
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 20px;
    background-color: whitesmoke;
    cursor: not-allowed;
}

.submit-venmo {
    width: fit-content;
    height: 50px;
    border: none;
    color: white;
    background-color: black;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    padding: 10px 20px;
}

.update-venmo {
    width: fit-content;
    height: 50px;
    border: none;
    color: white;
    background-color: black;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    margin-top: 30px;
    padding: 10px 20px;
}

.payment-flex {
    display: flex;
    gap: 160px
}

.payment-check {
    font-size: 50px;
    color: green;
    margin-top: 25px;
}


@media (max-width: 610px) {
    .payment-container {
        padding: 30px;
        margin-top: 100px;
    }

    .payment-flex {
        display: flex;
        gap: 10px
    }

}